import React, { forwardRef } from "react";
import Popup from "../Popup.jsx";
import { useIntl, defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "translations.account.title",
        defaultMessage: "Трансляция"
    },
    accountNotReady: {
        id: "translations.account.not_ready",
        defaultMessage: "Включите функцию прямых трансляций"
    },
    action: {
        id: "translations.account.action",
        defaultMessage: "Для подключения {platform} аккаунта необходимо настроить в нем возможность запуска трансляций"
    },
    settings: {
        id: "translations.account.settings",
        defaultMessage: "Перейти к настройке аккаунта"
    },
    changeAccount: {
        id: "translations.account.change",
        defaultMessage: "Выбрать другой аккаунт"
    }
});

const AccountNotReady = ({ type, isOpen, onClose, onChangeStreamAccount, gamecenter }) => {
    const intl = useIntl();
    const isVkLive = type === "vkplay_live";
    const handleClickStreamerBtn = (e) => {
        e.preventDefault();

        if (window.gc_open_external_browser) {
            if (isVkLive) {
                if (typeof gc_become_streamer != "undefined") {
                    window.gc_become_streamer("https://live.vkplay.ru/app/become-streamer"); // добавляем code и открываем в браузере
                    // window.gc_close_window(); // если надо закрыть текущее окно
                }
            } else {
                window.gc_open_external_browser("https://www.youtube.com/live_dashboard_splash?nv=1");
            }
        }
    };

    if (gamecenter) {
        return (
            <div className="b-popup__inner">
                <div className="content">
                    <div className="account-not-ready__icon">
                        <img src={require("../../../img/translation/icon_account-not-ready.png")} alt="" className="account-not-ready__img" />
                    </div>
                    <p className="account-not-ready__text">{intl.formatMessage(messages.accountNotReady)}</p>
                    <p className="account-not-ready__text">{intl.formatMessage(messages.action, {
                        platform: isVkLive ? "VK Play Live" : "YouTube"
                    })}</p>
                    <div className="account-not-ready__action">
                        <a
                            onClick={handleClickStreamerBtn}
                            className="ph-form__btn ph-btn"
                        >
                            {intl.formatMessage(messages.settings)}
                        </a>

                        {!isVkLive && <a className="ph-form__btn ph-form__btn_save ph-btn"
                            onClick={onChangeStreamAccount}>
                            {intl.formatMessage(messages.changeAccount)}
                        </a>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Popup title={intl.formatMessage(messages.title)}
            headerAlign={"left"}
            isOpen={isOpen}
            onClose={onClose}
            type={"account-not-ready"}>
            <div className="b-popup__inner">
                <div className="content">
                    <div className="account-not-ready__icon">
                        <img src={require("../../../img/translation/icon_account-not-ready.png")} alt="" className="account-not-ready__img" />
                    </div>
                    <p className="account-not-ready__text">{intl.formatMessage(messages.accountNotReady)}</p>
                    <p className="account-not-ready__text">{intl.formatMessage(messages.action, {
                        platform: isVkLive ? "VK Play Live" : "YouTube"
                    })}</p>
                    <div className="account-not-ready__action">
                        <a href={isVkLive ? "https://live.vkplay.ru/app/become-streamer" : "https://www.youtube.com/live_dashboard_splash?nv=1"} target="_blank" type="submit"
                            className="ph-form__btn ph-btn">{intl.formatMessage(messages.settings)}</a>

                        {!isVkLive && <button type="submit"
                            className="ph-form__btn ph-form__btn_save ph-btn"
                            onClick={onChangeStreamAccount}>
                            {intl.formatMessage(messages.changeAccount)}
                        </button>}
                    </div>
                </div>
            </div>
        </Popup>
    );
};



export default AccountNotReady;
