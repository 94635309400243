import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { isVkPlay, Backend, inGamecenter } from '@vkplay/shared';
import Popup from '../Popup.jsx';
import messages from './DeleteMessages';
import { STORAGE_USER_DELETED } from "../../_utils";

const SUPPORT_URL = isVkPlay ? 'https://support.vkplay.ru/vkp/personal' : 'https://support.my.games/app/personal';
const SUPPORT_DELETE_URL = 'https://support.vkplay.ru/vkp/personal/3683/newticket';
const API_URL = '/account/user_delete/';

const DeleteContent = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const intl = useIntl();

    const openDeletePopup = () => {
        setIsPopupOpen(true);
    };

    const closeDeletePopup = () => {
        setIsPopupOpen(false);
    };

    const handleDeleteError = (e) => {
        console.warn(e);
        window.location.href = SUPPORT_DELETE_URL;
    }

    const handleDeleteAccount = async () => {
        try {
            const data = await Backend.api_post(API_URL);

            if (data.status === 'ok') {
                if (inGamecenter && window.gc_new_notification_received) {
                    window.gc_new_notification_received({
                        title: intl.formatMessage(messages.inProgress),
                    });
                }

                try {
                    localStorage.setItem(STORAGE_USER_DELETED, '1');
                } catch (error) {
                    console.warn('Error setting localStorage deleted', error);
                }

                window.location.reload();
            } else {
                handleDeleteError(data);
            }
        } catch (e) {
            handleDeleteError(e);
        }
    };

    return (
        <>
            <h2 className="b-profile-box__heading">{intl.formatMessage(messages.header)}</h2>
            <div className="b-profile-box__content">
                <p>
                    {intl.formatMessage(messages.descr)}
                </p>
                <a href={SUPPORT_URL} target="_blank" className="b-profile__btn">
                    {intl.formatMessage(messages.header)}
                </a>
                {isVkPlay &&
                    <div className="b-profile-box__delete-block">
                        <button
                            className="b-link b-profile-box__delete-link"
                            onClick={openDeletePopup}
                        >
                            {intl.formatMessage(messages.deleteAccount)}
                        </button>
                    </div>
                }
            </div>

            <Popup
                isOpen={isPopupOpen}
                onClose={closeDeletePopup}
                className="b-delete-account-popup"
            >
                <div className="b-delete-account-popup__title">
                    {intl.formatMessage(messages.deletePopupTitle)}
                </div>

                <div className="b-delete-account-popup__descr">
                    {intl.formatMessage(messages.deletePopupDescr)}
                </div>

                <div className="ph-alert ph-alert__info">
                    {intl.formatMessage(messages.deletePopupAlert)}
                </div>

                <button
                    className="b-delete-account-popup__btn ph-btn ph-btn_lg"
                    onClick={closeDeletePopup}
                >
                    {intl.formatMessage(messages.deletePopupCancel)}
                </button>
                <button
                    className="b-delete-account-popup__btn ph-btn ph-btn_lg ph-btn_dark"
                    onClick={handleDeleteAccount}
                >
                    {intl.formatMessage(messages.deleteAccount)}
                </button>
            </Popup>
        </>
    );
};

export default DeleteContent;