import React from 'react';
import ReactDOM from 'react-dom';

import Newsletter from './components/Newsletter.jsx';


const NewsletterApp = {
    init: function () {

       let newsletterContainer = document.getElementById('newsletter');

        newsletterContainer && ReactDOM.render(<Newsletter />, newsletterContainer);
    }
}



export default NewsletterApp

