import { useEffect, useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import ajax from "superagent";
import baron from "baron";
import InputMask from "inputmask";
import {
    inGamecenter,
    Backend,
    htmlUnescape,
    calculateAge,
} from "@vkplay/shared";

import * as Utils from "./_utils.js";

let savePreviousSelectorValue = "";

const messages = defineMessages({
    not_found: {
        id: "not_found",
        defaultMessage: "Ничего не найдено",
    }
});

const PERSONAL_INFO_VARS = {
    sidebarFixedClass: "is-sidebar-fixed",
    currentGameData: {},
    selectedGames: [],
    isMob: Utils.isMob(),
    countryScrollPage: 1,
    countriesLoaded: false,
    scrolledToPercent: false,
    minAge: 18,
};

const PERSONAL_INFO_SELECTORS = {
    header: ".js-header",
    form: ".js-profile-form",
    sidebar: ".js-profile__menu",
    menuItem: ".js-profile__menu-item",
    mobMenu: ".js-menu-wrapper",
    mobMenuPlaceholder: ".js-menu-placeholder",
    boxes: ".js-profile-box",
    selectDefault: ".js-select-default",
    selectItem: ".js-select-link",
    showAll: ".js-show-all",
    copy: ".js-copy",
    inputShow: ".js-input-show",
    gameAddBtn: ".js-game-add-btn",
    gamesList: ".js-games-list",
    gameItem: ".js-game-item",
    notificationsOptions: ".js-game-notifications-options",
    formInput: ".js-form__input",
    inputBirth: ".js-from__input-birth",
    ageLimitWarning: ".js-age-limit-warning",
    birthdayHidden: "[name=\"birthday\"]",
    birthmonthHidden: "[name=\"birthmonth\"]",
    birthyearHidden: "[name=\"birthyear\"]",
    countryInput: "[name=\"country_id\"]",
    nicknameInput: "[name=\"nickname\"]",
    firstnameInput: "[name='first_name']",
    lastnameInput: "[name='last_name']",
    submitBtn: ".js-submit-btn",
    formError: ".js-ph-alert",
    emailVerificationStateOK: ".js-profile__email_verification_state .formMsg_ok",
    emailVerificationStateNOK: ".js-profile__email_verification_state .formMsg_error",
    sendVerificationState: ".js-profile__send_verification_state",
    sendVerification: ".js-profile__sendVerification",
    verificationSuccess: ".js-profile__verificationSuccess",
    verificationError: ".js-profile__verificationError",
    verificationTimeout: ".js-profile__verificationTimeout",
    notify: ".js-notify",
    translationInfo: ".js-translation-info",
    translationInfoVisibility: ".js-translation-info-toggle",
    logoutBtn: ".js-logout",
    gcScroller: ".js-gc-scrollable__scroller",
    aboutTextarea: ".js-about-textarea"
};

export const showNotify = (options = { type: "save", text: "" }) => {
    let notify = document.querySelector(PERSONAL_INFO_SELECTORS.notify);

    if (window.notifyTimeout) {
        clearTimeout(window.notifyTimeout);
    }

    notify.innerHTML = options.type === "save" ? notify.dataset.savetext : options.text;
    notify.classList.add("is-shown");
    window.notifyTimeout = setTimeout(() => {
        notify.classList.remove("is-shown");
    }, 4000);
};

export const copyText = (text) => {
    if (!text) {
        return;
    }

    text = text.toString();

    const inputEl = document.createElement("input");

    document.body.appendChild(inputEl);
    inputEl.value = text;
    inputEl.select();
    document.execCommand("copy");
    document.body.removeChild(inputEl);
};

const useProfileSettings = () => {
    const setUrl = useCallback((alias) => {
        const url = "/profile/" + alias + "/";

        window.history.pushState && window.history.pushState({ page: "profileSettings", alias: alias }, null, url);
    }, []);

    const setActiveMobMenu = useCallback((alias) => {
        let placeholder = document.querySelector(PERSONAL_INFO_SELECTORS.mobMenuPlaceholder),
            menuItemList = document.querySelector(PERSONAL_INFO_SELECTORS.sidebar),
            menuItemAlias = alias === "userinfo" ? "personal" : alias,
            menuItem = menuItemList.querySelector(`[data-alias="${menuItemAlias}"]`),
            currentMenu = document.querySelectorAll(`[data-alias="${placeholder.dataset.alias}"]`)[1];

        placeholder.innerHTML = menuItem.innerHTML;
        placeholder.dataset.alias = menuItem.dataset.alias;
        menuItem.style.display = "none";

        if (menuItem !== currentMenu) {
            currentMenu && (currentMenu.style.display = "block");
        }
    }, []);

    const dispatchAlias = useCallback((alias) => {
        if (!alias) {
            return;
        }

        if (Utils.isMob()) {
            setActiveMobMenu(alias);
        }

        const $boxesList = Array.from(document.querySelectorAll(PERSONAL_INFO_SELECTORS.boxes)),
            $box = $boxesList.filter((el) => el.dataset.alias === alias),
            $menuItems = document.querySelectorAll(PERSONAL_INFO_SELECTORS.menuItem);

        if ($box.length > 0) {
            if (PERSONAL_INFO_VARS.isMob) {
                Utils.scrollTo($box[0], 55);
            } else {
                Utils.scrollTo($box[0], 20);
            }

            setTimeout(() => {
                for (const $elem of $menuItems) {
                    $elem.classList.remove("active");

                    if ($elem.dataset.alias === alias) {
                        $elem.classList.add("active");
                    }
                }
            }, 950);

            setUrl(alias);
        }
    }, [setActiveMobMenu, setUrl]);

    const stickSidebar = useCallback((withGcScroller) => {
        let $sidebar = document.querySelector(PERSONAL_INFO_SELECTORS.sidebar),
            $sidebarWrapper = $sidebar.parentNode,
            $header = document.querySelector(PERSONAL_INFO_SELECTORS.header) || {},
            gcScroller = document.querySelector(PERSONAL_INFO_SELECTORS.gcScroller),
            scrollTop = withGcScroller ? gcScroller.scrollTop : (window.scrollY || window.pageYOffset || document.documentElement.scrollTop),
            contentHeight = $sidebarWrapper.offsetHeight,
            headerHeight = $header.offsetHeight || 0,
            needStick = scrollTop > headerHeight + $sidebarWrapper.offsetTop + 20,
            footer = document.querySelector("#ph-footer") || {},
            footerOffsetTop = footer.getBoundingClientRect ? footer.getBoundingClientRect().top : 0,
            sidebarMarginTop = 20,
            contentMarginBottom = 50;

        document.body.classList[needStick ? "add" : "remove"](PERSONAL_INFO_VARS.sidebarFixedClass);

        if (PERSONAL_INFO_VARS.isMob) {
            if (scrollTop >= 84) {
                $sidebarWrapper.style.position = "fixed";
                $sidebarWrapper.style.top = 0;
                $sidebarWrapper.style.left = 0;
                $sidebarWrapper.style.marginBottom = 0;
                $sidebarWrapper.classList.add("menu_fixed");

                return false;
            } else {
                $sidebarWrapper.style.position = "";
                $sidebarWrapper.style.top = "";
                $sidebarWrapper.style.left = "";
                $sidebarWrapper.style.marginBottom = "20px";
                $sidebarWrapper.classList.remove("menu_fixed");

                return false;
            }
        } else {
            if (inGamecenter && scrollTop > headerHeight + 20) {
                $sidebar.style.position = "fixed";
                $sidebar.style.top = "20px";
                $sidebar.style.left = "auto";

                return false;
            }
            if (!inGamecenter && scrollTop > headerHeight + 20 && $sidebar.clientHeight + sidebarMarginTop + contentMarginBottom < footerOffsetTop) {
                $sidebar.style.position = "fixed";
                $sidebar.style.top = "20px";
                $sidebar.style.left = "auto";
            } else if (!inGamecenter && scrollTop > headerHeight + 20 && $sidebar.clientHeight + sidebarMarginTop + contentMarginBottom > footerOffsetTop) {
                $sidebar.style.position = "absolute";
                $sidebar.style.top = contentHeight - 10 - $sidebar.clientHeight + "px";
                $sidebar.style.left = "auto";
            } else {
                $sidebar.style.position = "";
                $sidebar.style.top = "";
            }
        }
    }, []);

    const setScrollSpy = useCallback(() => {
        let $boxes = document.querySelectorAll(PERSONAL_INFO_SELECTORS.boxes),
            $menuItems = document.querySelectorAll(PERSONAL_INFO_SELECTORS.menuItem);

        for (const $box of $boxes) {
            let top = $box.getBoundingClientRect().top,
                alias = $box.dataset.alias;

            if (PERSONAL_INFO_VARS.isMob && top > 60) {
                return;
            } else if (!PERSONAL_INFO_VARS.isMob && top > 20) {
                return;
            }

            for (const $elem of $menuItems) {
                if ($elem.classList.contains("active") && $elem.dataset.alias !== alias) {
                    $elem.classList.remove("active");
                } else if ($elem.dataset.alias === alias) {
                    $elem.classList.add("active");
                    if (PERSONAL_INFO_VARS.isMob) {
                        setActiveMobMenu(alias);
                    }
                }
            }
        }
    }, [setActiveMobMenu]);

    const toggleCheckboxes = useCallback(($parent, shouldCheck) => {
        if (!$parent) {
            return;
        }

        const $checkboxes = $parent.querySelectorAll("input[type=checkbox]");

        for (const $check of $checkboxes) {
            $check.checked = shouldCheck;
        }
    }, []);

    const toggleInputs = useCallback((input) => {
        let inputParent = Utils.findAncestor(input, ".ph-form__field");

        if (input.value) {
            inputParent.classList.add("is-filled");
        } else {
            inputParent.classList.remove("is-filled");
        }
    }, []);

    const onGameSelect = useCallback((game, name) => {
        let $btn = document.querySelector(PERSONAL_INFO_SELECTORS.gameAddBtn),
            $options = document.querySelector(PERSONAL_INFO_SELECTORS.notificationsOptions),
            disabledClass = "btn_disabled",
            visibleClass = "is-visible";

        if (game) {
            PERSONAL_INFO_VARS.currentGameData.id = game;
            PERSONAL_INFO_VARS.currentGameData.name = name;
            $btn.disabled = false;
            $btn.classList.remove(disabledClass);
            $options.classList.add(visibleClass);
            toggleCheckboxes($options, true);
        } else {
            $btn.disabled = true;
            $btn.classList.add(disabledClass);
            $btn.dataset.game = "";
            $options.classList.remove(visibleClass);
        }
    }, [toggleCheckboxes]);

    const renderGameItem = useCallback((id, name) => {
        return (
            `<div class="b-profile-box__game js-game-item" data-game="${id}">` +
            `<div class="b-profile-box__game-name">${name}</div>` +
            "<a href=\"#\" class=\"b-profile-box__game-settings js-game-settings\"></a>" +
            "<a href=\"#\" class=\"b-profile-box__game-remove js-game-remove\"></a>" +
            "</div>"
        );
    }, []);

    const addGameNotifications = useCallback(() => {
        let game = PERSONAL_INFO_VARS.currentGameData,
            $gameItems = document.querySelectorAll(PERSONAL_INFO_SELECTORS.gameItem),
            $container = document.querySelector(PERSONAL_INFO_SELECTORS.gamesList),
            $wrapperElem = document.createElement("div"),
            $elem;

        for (const $item of $gameItems) {
            if ($item.dataset.game === game.id) {
                return;
            }
        }

        $wrapperElem.innerHTML = renderGameItem(game.id, game.name);
        $elem = $wrapperElem.firstChild;
        $container.appendChild($elem);
    }, [renderGameItem]);

    const onBodyMove = useCallback((event) => {
        const menu = document.getElementsByClassName("b-profile__menu")[0];

        if (menu && menu.scrollHeight > menu.clientHeight) {
            if (!(event.target.classList.contains("b-profile__menu-text") || event.target.classList.contains("b-profile__menu-item"))) event.preventDefault();
        } else {
            event.preventDefault();
        }
    }, []);

    const toggleMobMenu = useCallback(() => {
        let $menuWrapper = document.querySelector(PERSONAL_INFO_SELECTORS.mobMenu);

        document.documentElement.classList.toggle("no-scroll");
        document.body.classList.toggle("no-scroll");
        $menuWrapper.classList.toggle("is-opened");

        if (document.body.classList.contains("no-scroll")) {
            document.body.addEventListener("touchmove", onBodyMove, { passive: false });
        } else {
            document.body.removeEventListener("touchmove", onBodyMove);
        }
    }, []);

    const toggleTranslationVisibility = useCallback(() => {
        let $translationInfo = document.querySelectorAll(PERSONAL_INFO_SELECTORS.translationInfo);

        for (let $elem of $translationInfo) {
            $elem.classList.toggle("is-opened");
        }
    }, []);

    const validateForm = useCallback((form) => {
        let validate = true;
        let errorField = document.querySelectorAll(PERSONAL_INFO_SELECTORS.formError);
        let formFields = document.querySelectorAll(".ph-form__field");

        for (let $elem of errorField) {
            $elem.style.display = "none";
        }

        for (let $elem of formFields) {
            $elem.classList.remove("ph-form__field_error");
        }

        switch (form.dataset.form) {
            case "personal":
                const nicknameInput = document.querySelector("[name='nickname']"),
                    firstnameInput = document.querySelector("[name='first_name']"),
                    lastnameInput = document.querySelector("[name='last_name']"),
                    nicknameInputVal = nicknameInput.value,
                    firstnameInputVal = firstnameInput.value,
                    lastnameInputVal = lastnameInput.value,
                    nickNameField = Utils.findAncestor(nicknameInput, ".ph-form__field"),
                    firstnameField = Utils.findAncestor(firstnameInput, ".ph-form__field"),
                    lastnameField = Utils.findAncestor(lastnameInput, ".ph-form__field"),
                    errorField = document.querySelector(`[data-form="personal"] ${PERSONAL_INFO_SELECTORS.formError}`),
                    regExp = /^\s+|[;<>&#]|<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;

                if (nicknameInputVal.match(regExp) ||
                    !nicknameInputVal.length ||
                    (nicknameInputVal.length && nicknameInputVal.length < 3) ||
                    nicknameInputVal.length > 25) {
                    errorField.innerHTML = errorField.dataset.error3;
                    errorField.style.display = "block";
                    nickNameField.classList.add("ph-form__field_error");
                    validate = false;
                }
                else if (firstnameInputVal.match(regExp) || (firstnameInputVal.length && firstnameInputVal.length < 2) || firstnameInputVal.length > 25) {
                    errorField.innerHTML = errorField.dataset.error2;
                    errorField.style.display = "block";
                    firstnameField.classList.add("ph-form__field_error");
                    validate = false;
                }
                else if (lastnameInputVal.match(regExp) || (lastnameInputVal.length && lastnameInputVal.length < 2) || lastnameInputVal.length > 25) {
                    errorField.innerHTML = errorField.dataset.error2;
                    errorField.style.display = "block";
                    lastnameField.classList.add("ph-form__field_error");
                    validate = false;
                }
                break;
            case "visibility":
                let visibilityList = Array.prototype.slice.call(document.querySelectorAll("[name=\"visibility\"]"));

                validate = false;

                visibilityList.forEach(item => {
                    if (!validate) {
                        validate = item.checked;
                    }
                });
                break;
            default:
                validate = true;

        }

        return validate;
    }, []);

    const formSubmit = useCallback((form, url) => {
        if (!validateForm(form)) {
            return false;
        }

        let data = Utils.serialize(form);

        ajax.post(url)
            .set("Content-Type", "application/x-www-form-urlencoded")
            .send(data)
            .withCredentials()
            .end((error, response) => {
                // error for accounts
                if (response.body.error) {
                    let errorFields = typeof (response.body.error) === "string" ? "" : Object.keys(response.body.error),
                        errorClass = "ph-form__field_error";

                    errorFields && errorFields.forEach(fieldName => {
                        let errorInput = document.querySelector(`[name=${fieldName}]`);

                        Utils.findAncestor(errorInput, ".ph-form__field").classList.add(errorClass);
                    });

                    return false;
                }
                if (response.status === 200) {
                    showNotify();

                    /* Update leela state on success change */
                    if (window.__GEMS && window.__GEM) {
                        window.__GEMS.current_user.profile_fields = { ...window.__GEMS.current_user.profile_fields, ...data };
                        window.__GEMS.handleChangeNickReminderClick && window.__GEMS.handleChangeNickReminderClick();
                        window.__GEM.renderHeader();
                    }

                    window.gc_refresh_account && window.gc_refresh_account();

                    /* Show age limit notification if user is smol */
                    const birthdate = `${data.birthyear}-${data.birthmonth}-${data.birthday}`;
                    const age = calculateAge(birthdate);
                    const $inputBirth = document.querySelector(PERSONAL_INFO_SELECTORS.inputBirth);
                    const $ageLimitWarning = document.querySelector(PERSONAL_INFO_SELECTORS.ageLimitWarning);

                    if (typeof age === "number" && age < PERSONAL_INFO_VARS.minAge && $inputBirth && $ageLimitWarning) {
                        $inputBirth.setAttribute("disabled", "disabled");
                        $ageLimitWarning.style.display = "block";
                    }
                } else {
                    console.warn(error);
                }
            });
    }, [validateForm]);

    const checkBirthdate = useCallback(() => {
        let $inputBirth = document.querySelector(PERSONAL_INFO_SELECTORS.inputBirth),
            dates = $inputBirth.dataset.value.split("-"),
            validDate = true;

        dates.forEach(date => {
            if (Number(date) === 0) {
                validDate = false;
            }
        });

        if (!validDate) {
            $inputBirth.dataset.value = "";

            return false;
        }

        $inputBirth.value = dates.reverse().join("");
    }, []);

    const tickTimer = useCallback((timeout) => {
        if (!timeout) {
            return false;
        }
        let verificationTimeout = document.querySelector(PERSONAL_INFO_SELECTORS.verificationTimeout),
            text = verificationTimeout.innerHTML.split(" ");

        text[text.length - 2] = timeout;

        verificationTimeout.innerHTML = text.join(" ");

        setTimeout(() => {
            tickTimer(timeout - 1);
        }, 1000);
    }, []);

    const sendVerification = useCallback((url) => {
        Backend.post(url).then((response) => {
            const sendVerificationField = document.querySelector(PERSONAL_INFO_SELECTORS.sendVerification),
                verificationSuccessField = document.querySelector(PERSONAL_INFO_SELECTORS.verificationSuccess),
                verificationErrorField = document.querySelector(PERSONAL_INFO_SELECTORS.verificationError),
                verificationTimeout = document.querySelector(PERSONAL_INFO_SELECTORS.verificationTimeout);

            if (response.status === "ok") {
                sendVerificationField.style.display = "none";
                verificationSuccessField.style.display = "inline-block";
                verificationErrorField.style.display = "none";
                verificationTimeout.style.display = "none";
            } else if (response.timeout) {
                sendVerificationField.style.display = "none";
                verificationSuccessField.style.display = "none";
                verificationTimeout.innerHTML = response.error_message;
                verificationTimeout.style.display = "inline-block";
                tickTimer(response.timeout);
                setTimeout(() => {
                    sendVerificationField.style.display = "inline-block";
                    verificationErrorField.style.display = "none";
                    verificationSuccessField.style.display = "none";
                    verificationTimeout.style.display = "none";
                }, Number(response.timeout) * 1000);
            } else {
                console.warn();
                sendVerificationField.style.display = "none";
                verificationSuccessField.style.display = "none";
                verificationTimeout.style.display = "none";
                verificationErrorField.style.display = "inline-block";
            }
        });
    }, [tickTimer]);

    const initScrollbars = useCallback(() => {
        baron({
            root: ".js-gc-scrollable",
            scroller: ".js-gc-scrollable__scroller",
            track: ".js-gc-scrollable__track",
            bar: ".js-gc-scrollable__bar",
            barOnCls: "gc-baron-active"
        });
    }, []);

    const setMethods = useCallback(() => {
        const $menuItems = document.querySelectorAll(PERSONAL_INFO_SELECTORS.menuItem);
        const $selectDropdown = document.querySelectorAll(PERSONAL_INFO_SELECTORS.selectDropdown);
        const $copyLink = document.querySelectorAll(PERSONAL_INFO_SELECTORS.copy);
        const $showAll = document.querySelectorAll(PERSONAL_INFO_SELECTORS.showAll);
        const $inputShow = document.querySelectorAll(PERSONAL_INFO_SELECTORS.inputShow);
        const $gameAddBtn = document.querySelector(PERSONAL_INFO_SELECTORS.gameAddBtn);
        const $formInput = document.querySelectorAll(PERSONAL_INFO_SELECTORS.formInput);
        const $inputBirth = document.querySelector(PERSONAL_INFO_SELECTORS.inputBirth);
        const $inputDayHidden = document.querySelector(PERSONAL_INFO_SELECTORS.birthdayHidden);
        const $inputMonthHidden = document.querySelector(PERSONAL_INFO_SELECTORS.birthmonthHidden);
        const $inputYearHidden = document.querySelector(PERSONAL_INFO_SELECTORS.birthyearHidden);
        const $selectDropdownList = document.querySelectorAll(PERSONAL_INFO_SELECTORS.selectDropdownList);
        const $mobMenu = document.querySelector(PERSONAL_INFO_SELECTORS.mobMenu);
        const $submitBtn = document.querySelectorAll(PERSONAL_INFO_SELECTORS.submitBtn);
        const $sendVerification = document.querySelector(PERSONAL_INFO_SELECTORS.sendVerification);
        const $nicknameInput = document.querySelector(PERSONAL_INFO_SELECTORS.nicknameInput);
        const $emailVerificationStateOK = document.querySelector(PERSONAL_INFO_SELECTORS.emailVerificationStateOK);
        const $emailVerificationStateNOK = document.querySelector(PERSONAL_INFO_SELECTORS.emailVerificationStateNOK);
        const $sendVerificationState = document.querySelector(PERSONAL_INFO_SELECTORS.sendVerificationState);
        const $translationInfoToggle = document.querySelector(PERSONAL_INFO_SELECTORS.translationInfoVisibility);
        const gcScroller = document.querySelector(PERSONAL_INFO_SELECTORS.gcScroller);
        const $aboutTextarea = document.querySelector(PERSONAL_INFO_SELECTORS.aboutTextarea);

        if (inGamecenter) {
            gcScroller.addEventListener("scroll", () => {
                stickSidebar(true);
                setScrollSpy();
            });
            $nicknameInput.value = htmlUnescape($nicknameInput.value);
        } else {
            document.addEventListener("scroll", () => {
                stickSidebar();
                setScrollSpy();
            });
        }

        window.addEventListener("gem_login", (ev) => {
            const wsd = window.__gem_wsd;

            $nicknameInput.value = htmlUnescape(ev.detail.profile_fields.nickname);
            toggleInputs($nicknameInput);

            wsd.on("message", (message) => {
                if (message.type === "refresh_nickname") {
                    $nicknameInput.value = htmlUnescape(message.nickname);
                    $nicknameInput.parentElement.parentElement.classList.add("is-filled");
                    toggleInputs($nicknameInput);
                }
                if (message.type === "email_verified") {
                    $sendVerificationState.style.display = "none";
                    $emailVerificationStateOK.style.display = "inline-block";
                    $emailVerificationStateNOK.style.display = "none";
                }
            });
        });

        if (PERSONAL_INFO_VARS.isMob) {
            $mobMenu.addEventListener("click", toggleMobMenu);
        }


        checkBirthdate();

        let inputMaskSettings = () => {
            InputMask({
                mask: "99.99.9999",
                autoUnmask: true,
                placeholder: $inputBirth.dataset.text,
                clearIncomplete: true,
                showMaskOnHover: false,
                onincomplete() {
                    $inputDayHidden.value = "";
                    $inputMonthHidden.value = "";
                    $inputYearHidden.value = "";
                },
                oncomplete() {
                    let inputDate = $inputBirth.inputmask.unmaskedvalue(),
                        day = inputDate.substring(0, 2).replace(/^0/, ""),
                        month = inputDate.substring(2, 4).replace(/^0/, ""),
                        year = inputDate.substring(4);

                    const dateWithoutZero = day == 0 || month == 0 || year == 0;

                    if (day > 31 || month > 12 || dateWithoutZero || new Date().getFullYear() - year > 100 || year > new Date().getFullYear()) {
                        $inputBirth.value = "";
                        inputMaskSettings();

                        return false;
                    }

                    $inputDayHidden.value = day;
                    $inputMonthHidden.value = month;
                    $inputYearHidden.value = year;
                }
            }).mask($inputBirth);
        };

        inputMaskSettings();


        // Menu items dispatchAlias
        for (const $elem of $menuItems) {
            const alias = $elem.dataset.alias;

            $elem.addEventListener("click", (e) => {
                e.preventDefault();
                dispatchAlias(alias);
            });
        }

        const checkTextBan = () => {
            return window.taboo_txt;
        };

        for (const $elem of $formInput) {
            toggleInputs($elem);

            $elem.addEventListener("input", (e) => {
                let inputParent = Utils.findAncestor(e.target, ".ph-form__field");

                inputParent.classList.add("is-focused");
            });

            $elem.addEventListener("focus", (e) => {
                let inputParent = Utils.findAncestor(e.target, ".ph-form__field");

                if (checkTextBan() && !e.target.classList.contains("js-from__input-birth")) {
                    e.preventDefault();
                    e.stopPropagation();

                    document.getElementById("ban-txt-popup").style.display = "block";

                    return false;
                }

                inputParent.classList.add("is-focused");
            });

            $elem.addEventListener("blur", (e) => {
                let input = e.target,
                    inputParent = Utils.findAncestor(e.target, ".ph-form__field");

                if (input.value) {
                    inputParent.classList.add("is-filled");
                } else {
                    inputParent.classList.remove("is-filled");
                    inputParent.classList.remove("is-focused");
                }
            });
        }

        $aboutTextarea.addEventListener("focus", (e) => {
            if (checkTextBan()) {
                e.preventDefault();
                e.stopPropagation();

                document.getElementById("ban-txt-popup").style.display = "block";

                return false;
            }
        });

        function checkLimit(field, event) {
            const rows = field.getAttribute("rows");
            let text = event.target.value;
            let lines = text.split("\n");

            if (event.type === "paste") {
                return setTimeout(() => {
                    event.preventDefault();

                    const copyTxt = event.target.value
                        .split("\n")
                        .map(item => item + "\n");

                    const uniqArray = copyTxt.filter((item, pos, arr) => !pos || item !== arr[pos - 1]);

                    return field.value = uniqArray.splice(0, Number(rows)).join("");
                }, 10);
            } else if (event.keyCode === 13) {

                if (lines.length >= rows) {
                    event.preventDefault();

                    return false;
                }
            }
        }

        $aboutTextarea.addEventListener("keypress", function(e) {
            return checkLimit(this, e);
        });
        $aboutTextarea.addEventListener("paste", function(e) {
            return checkLimit(this, e);
        });

        window.onpopstate = (e) => {
            const state = e.state;

            if (state && state.page === "profileSettings") {
                dispatchAlias(state.alias);
            }
        };

        const selectElemCallback = (e, $elem) => {
            const $openedElem = Array.from($selectDropdown).find(el => el.classList.contains("ph-select_opened"));
            const input = $elem.querySelector(PERSONAL_INFO_SELECTORS.selectDefault);

            e.preventDefault();

            if  (e.target.classList.contains("b-gc-scrollable__track-container") ||
                e.target.classList.contains("b-gc-scrollable__track") ||
                e.target.classList.contains("b-gc-scrollable__bar")) {
                return false;
            }

            if ($openedElem) {
                const openedInput = $openedElem.querySelector(PERSONAL_INFO_SELECTORS.selectDefault);

                if (openedInput) {
                    openedInput.value = savePreviousSelectorValue;
                    openedInput.blur();
                    input.blur();
                }
            } else {
                $elem.classList.add("ph-select_opened");
                savePreviousSelectorValue = input.value;
                input.value = "";
                input.focus();
            }
        };

        // Select dropdown list
        for (const $elem of $selectDropdown) {
            $elem.addEventListener("click", (e) => selectElemCallback(e, $elem));
        }

        for (let $btn of $submitBtn) {
            $btn.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                let form = Utils.findAncestor(e.target, ".js-profile-form");
                let url = e.target.dataset.url;

                formSubmit(form, url);
            });
        }


        document.addEventListener("click", (e) => {
            for (const $elem of $selectDropdown) {
                if (!(e.target.classList.contains("js-select") || $elem.contains(e.target))) {
                    if  (e.target.classList.contains("b-gc-scrollable__track-container") ||
                        e.target.classList.contains("b-gc-scrollable__track") ||
                        e.target.classList.contains("b-gc-scrollable__bar")) {
                        return false;
                    }
                    if ($elem.classList.contains("ph-select_opened")) {
                        const input = $elem.querySelector(PERSONAL_INFO_SELECTORS.selectDefault);

                        input.value = savePreviousSelectorValue;
                    }
                }
            }
        });

        // Select items
        for (const $elem of $selectDropdownList) {
            $elem.addEventListener("click", (e) => {
                if (e.target.classList.contains("js-select-link")) {
                    e.preventDefault();
                    e.stopPropagation();
                    const $item = e.target;
                    const $list = Utils.findAncestor($elem, PERSONAL_INFO_SELECTORS.selectDropdown);
                    const $form = Utils.findAncestor($elem, PERSONAL_INFO_SELECTORS.form);
                    const $itemActive = $list.querySelector(".selected");
                    const $default = $list.querySelector(PERSONAL_INFO_SELECTORS.selectDefault);
                    const name = $list.dataset.name;
                    const value = $item.dataset.value;
                    const $input = $form && $form.querySelector(`input[name="${name}"]`);

                    $default.value = $item.innerHTML;
                    $itemActive && $itemActive.classList.remove("selected");
                    Utils.findAncestor($item, ".ph-select__dropdown-code").classList.add("selected");

                    if ($input) {
                        $input.value = value;
                    }

                    $list.classList.add("ph-select_has-selected");

                    if ($list.classList.contains("js-game-select")) {
                        onGameSelect(value, $elem.innerHTML);
                    }
                }
            });
        }

        /* Show all link */

        for (const $elem of $showAll) {
            let target = $elem.dataset.target,
                $targetEl = document.getElementById(target);

            $elem.addEventListener("click", (e) => {
                e.preventDefault();

                if ($targetEl && !$targetEl.classList.contains("expanded")) {
                    $targetEl.classList.add("expanded");

                    if (typeof $elem.remove === "function") {
                        $elem.remove();
                    } else {
                        $elem.parentNode.removeChild($elem);
                    }
                }
            });
        }

        if ($sendVerification) {
            $sendVerification.addEventListener("click", (e) => {
                e.preventDefault();
                sendVerification(e.target.href);
            });
        }

        /* Copy link */

        for (const $link of $copyLink) {
            const inputId = $link.dataset.copySource;
            const $input = document.getElementById(inputId);

            if ($input.value === "") {
                $link.style.display = "none";
            }

            $link.addEventListener("click", (e) => {
                e.preventDefault();

                if ($input) {
                    $input.select();
                    document.execCommand("copy");
                    $link.innerHTML = $link.dataset.copied;
                    setTimeout(() => {
                        $link.innerHTML = $link.dataset.copy;
                    }, 5000);
                }
            });
        }

        /* Show password input */

        for (const $elem of $inputShow) {
            const target = $elem.dataset.target;
            const $input = document.getElementById(target);

            if (!($input && $input.getAttribute("type") === "password")) {
                return;
            }

            $elem.addEventListener("click", (e) => {
                e.preventDefault();

                if ($input.getAttribute("type") === "password") {
                    $input.setAttribute("type", "text");
                } else {
                    $input.setAttribute("type", "password");
                }
            });
        }

        /* Add game notifications */

        if ($gameAddBtn) {
            $gameAddBtn.addEventListener("click", (e) => {
                e.preventDefault();
                addGameNotifications();
            });
        }

        $translationInfoToggle && $translationInfoToggle.addEventListener("click",  toggleTranslationVisibility);
    }, [
        addGameNotifications,
        checkBirthdate,
        dispatchAlias,
        formSubmit,
        onGameSelect,
        sendVerification,
        setScrollSpy,
        stickSidebar,
        toggleInputs,
        toggleMobMenu,
        toggleTranslationVisibility,
    ]);

    const init = useCallback(() => {
        if (document.body.classList.contains("b-profile-page")) {
            setMethods();

            window.scrollBy(0, -1);
            const startAlias = window.location.pathname.split("/").reverse()[1];

            setTimeout(() => {
                dispatchAlias(startAlias);
            }, 1000);

            if (inGamecenter) {
                initScrollbars();
            }
        }
    }, [dispatchAlias, initScrollbars, setMethods]);

    useEffect(() => {
        init();
    }, [init]);

    return null;
};

export default useProfileSettings;