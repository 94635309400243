import React from 'react';
import ReactDOM from 'react-dom';

import Delete from './components/Delete/Delete.jsx';


const DeleteApp = {
    init: function (props) {

        let visibilityContainer = document.getElementById('delete');

        visibilityContainer && ReactDOM.render(<Delete {...props} />, visibilityContainer);
    }
};



export default DeleteApp;

