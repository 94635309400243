import React from "react";
import { useIntl } from "react-intl";

import Popup from "../Popup.jsx";
import Button from "../shared/Button.jsx";

import messages from "./SecurityMessages";

const LogoutConfirmPopup = ({ login, onLogout, onClose }) => {
    const intl = useIntl();

    return (
        <Popup
            title={intl.formatMessage(messages.logout)}
            className="b-logout-popup"
            onClose={onClose}
            isOpen
        >
            <div className="b-logout-popup__content">
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(messages.logoutConfirm, { login })} } />
                <div className="b-logout-popup__action">
                    <Button onClick={onLogout}>
                        {intl.formatMessage(messages.yes)}
                    </Button>
                    <Button onClick={onClose} styling="friend">
                        {intl.formatMessage(messages.no)}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default LogoutConfirmPopup;