import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { Backend, origin_by_login, query_string, isVkPlay } from "@vkplay/shared";

import Radio from "../shared/Radio.jsx";
import SoonHint from "../shared/SoonHint.jsx";

import messages from "../../services/Messages";
import { checkIs2faEnabled, set2faEnabledFlag } from "./const";

import "../../../css/_security.css";

import myGamesAppQRCode from "../../../img/my_games_app_qr_code.gif";
import shieldIcon from "../../../img/svg/shield.svg";

const GOOGLE_PLAY_APP_LINK = "https://trk.my.com/c/yqxmn1";
const APP_STORE_APP_LINK = "https://trk.my.com/c/t5zr93";
const APP_GALLERY_APP_LINK = "https://trk.my.com/c/h89493";

const LOCALE_RU = "ru";

const AUTH_LEVEL_RADIO_NAME = "auth_level_radio";

const AUTH_LEVELS = {
    FULL_PROTECTED: 0,
    ONLY_ACTIONS: 1,
};

const QRCode = ({ src, className }) => {
    return (
        <img
            src={src}
            className="icon-qr_code"
            alt="qr code"
        />
    );
};

const DownloadGooglePlayButton = ({ className, soon = false }) => {
    const intl = useIntl();
    const badge = useMemo(() => {
        switch (intl.locale) {
            case LOCALE_RU:
                return "download_google_play_ru";
            default:
                return "download_google_play_us";
        }
    }, [intl.locale]);

    const handleLinkClick = useCallback((e) => {
        if (soon) {
            e.preventDefault();
        }
    }, [soon]);

    const disabledClassName = useMemo(() => soon ? "icon-disabled_state" : "", [soon]);

    return (
        <a
            href={GOOGLE_PLAY_APP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
            className={`download_app__link ${disabledClassName}`}
        >
            {soon && <SoonHint className="download_soon__hint" />}
            <img
                src={require(`../../../img/badges/${badge}.png`)}
                className={className}
                alt=""
            />
        </a>
    );
};

const DownloadAppStoreButton = ({ className, soon = false }) => {
    const intl = useIntl();
    const badge = useMemo(() => {
        switch (intl.locale) {
            case LOCALE_RU:
                return "download_app_store_ru";
            default:
                return "download_app_store_us";
        }
    }, [intl.locale]);

    const handleLinkClick = useCallback((e) => {
        if (soon) {
            e.preventDefault();
        }
    }, [soon]);

    const disabledClassName = useMemo(() => soon ? "icon-disabled_state" : "", [soon]);

    return (
        <a
            href={APP_STORE_APP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className={`download_app__link ${disabledClassName}`}
            onClick={handleLinkClick}
        >
            {soon && <SoonHint className="download_soon__hint" />}
            <img
                src={require(`../../../img/badges/${badge}.png`)}
                className={className}
                alt=""
            />
        </a>

    );
};

const DownloadAppGalleryButton = ({ className, soon = false }) => {
    const intl = useIntl();
    const badge = useMemo(() => {
        switch (intl.locale) {
            case LOCALE_RU:
                return "download_app_gallery_ru";
            default:
                return "download_app_gallery_us";
        }
    }, [intl.locale]);

    const handleLinkClick = useCallback((e) => {
        if (soon) {
            e.preventDefault();
        }
    }, [soon]);

    const disabledClassName = useMemo(() => soon ? "icon-disabled_state" : "", [soon]);

    return (
        <a
            href={APP_GALLERY_APP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className={`download_app__link ${disabledClassName}`}
            onClick={handleLinkClick}
        >
            {soon && <SoonHint className="download_soon__hint" />}
            <img
                src={require(`../../../img/badges/${badge}.png`)}
                className={className}
                alt=""
            />
        </a>

    );
};

const EnabledAuthLevelBlock = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [authLevel, setAuthLevel] = useState(null);
    const [errors, setErrors] = useState(() => query_string());

    const fetchCurrentAuthLevel = useCallback(async () => {
        setLoading(true);

        try {
            const response = await Backend.api_get("/account/twostep/single_auth/get/");

            setAuthLevel(response.single_step_auth);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const setCurrentAuthLevel = useCallback(async (event) => {
        setLoading(true);

        try {
            const single_step_auth = +event.target.value;

            const response = await Backend.api_post("/account/twostep/single_auth/set_init/", { single_step_auth });

            if (response.poll_page_url) {
                location.href = response.poll_page_url;
            } else {
                console.warn(response.status);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleError = useCallback(() => {
        const err = errors || {};
        const errCount = Object.keys(err).length;

        if (errCount) {
            setTimeout(() => {
                setErrors(null);
            }, 20000);
        }

        if (err["2fa_level_error"] || err["2fa_level_error"]) {
            return (
                <div className="ph-alert ph-alert_error">
                    {intl.formatMessage(messages.internalError)}
                </div>
            );
        }

        if (err["2fa_certify_error"]) {
            return (
                <div className="ph-alert ph-alert_error">
                    {intl.formatMessage(messages.errorPollTokenCancelled)}
                </div>
            );
        }

        return null;
    }, [errors]);

    useEffect(() => {
        fetchCurrentAuthLevel();
    }, [fetchCurrentAuthLevel]);

    return (
        <div className="b-profile-box__levels">
            <div className="b-profile-box__security-field__header">
                {intl.formatMessage(messages.accountLevelProtection)}
            </div>
            {handleError()}
            <div className="b-profile-box__wrapper">
                <div className="b-profile-box__level">
                    <Radio
                        disabled={loading}
                        name={AUTH_LEVEL_RADIO_NAME}
                        onChange={setCurrentAuthLevel}
                        value={AUTH_LEVELS.FULL_PROTECTED}
                        className="b-profile-box__level__radio"
                        checked={authLevel === AUTH_LEVELS.FULL_PROTECTED}
                    >
                        {intl.formatMessage(messages.fullProtection)}
                    </Radio>
                    <span className="b-profile-box__level__description">
                        {intl.formatMessage(messages.fullProtectionDescription)}
                    </span>
                </div>
                <div className="b-profile-box__level">
                    <Radio
                        disabled={loading}
                        name={AUTH_LEVEL_RADIO_NAME}
                        onChange={setCurrentAuthLevel}
                        value={AUTH_LEVELS.ONLY_ACTIONS}
                        className="b-profile-box__level__radio"
                        checked={authLevel === AUTH_LEVELS.ONLY_ACTIONS}
                    >
                        {intl.formatMessage(messages.onlyActions)}
                    </Radio>
                    <span className="b-profile-box__level__description">
                        {intl.formatMessage(messages.onlyActionsDescription)}
                    </span>
                </div>
            </div>
        </div>
    );
};

const EnabledAuthBlock = () => {
    const intl = useIntl();
    const origin = origin_by_login(window?.__GEMS?.current_user?.login);
    // Уровень защиты отображается только для соц. учеток
    const isEnabledAuthLevelBlockVisible = origin && origin !== "e" && origin !== "p";

    const handleDisableClick = useCallback(() => {
        if (window.__GEM && window.__GEM.showDisableTwoStepAuthPopup) {
            window.__GEM.showDisableTwoStepAuthPopup();
        }
    }, []);

    return (
        <>
            <div className="b-profile-box__twostep">
                <div className="b-profile-box__security-field__header">
                    {intl.formatMessage(messages.twoStepAuth)}
                </div>
                <div className="b-profile-box__row">
                    <img src={shieldIcon} className="icon-shield" alt=""/>
                    <div className="b-profile-box__twostep__wrapper">
                        <div className="b-profile-box__row__data">
                            <div className="b-profile-box__row__name">
                                <span>{intl.formatMessage(messages.auth)}</span>
                            </div>
                            <div className="b-profile-box__row__action">
                                <span
                                    className="b-link"
                                    onClick={handleDisableClick}
                                >
                                    {intl.formatMessage(messages.disable)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEnabledAuthLevelBlockVisible && <EnabledAuthLevelBlock />}
        </>
    );
};

const DisabledAuthBlock = (props) => {
    const { randomPass } = props;
    const [helpVisible, setHelpVisible] = useState(false);
    const intl = useIntl();

    const handleEnableClick = useCallback(() => {
        setHelpVisible(!helpVisible);
    }, [helpVisible]);

    const linkText = useMemo(() => {
        return intl.formatMessage(helpVisible ? messages.cancel : messages.enable);
    }, [intl, helpVisible]);

    const qrCode = useMemo(() => myGamesAppQRCode, []);

    let rowContent = (
        <div className="b-profile-box__row">
            <img src={shieldIcon} className="icon-shield" alt=""/>
            <div className="b-profile-box__twostep__wrapper">
                <div className="b-profile-box__row__data">
                    <div className="b-profile-box__row__name">
                        <span>
                            {intl.formatMessage(messages.auth)}
                        </span>
                    </div>
                    <div className="b-profile-box__row__value error">
                        {intl.formatMessage(messages.inactive)}
                    </div>
                    <div className="b-profile-box__row__action">
                        <span
                            className="b-link"
                            onClick={handleEnableClick}
                        >
                            {linkText}
                        </span>
                    </div>
                </div>
                {helpVisible && (
                    <div className="b-profile-box__twostep__help__block">
                        <div className="b-profile-box__twostep__help__block__row description">
                            {intl.formatMessage(messages.twoStepAuthDescription, { platform: isVkPlay ? "VK Play" : "MY.GAMES" })}
                        </div>
                        <div className="b-profile-box__twostep__help__block__row downloads">
                            <QRCode src={qrCode} />
                            <div className="download_links">
                                <DownloadGooglePlayButton
                                    soon={false}
                                    className="icon-google_play"
                                />

                                <DownloadAppGalleryButton
                                    soon={false}
                                    className="icon-app_gallery"
                                />

                                <DownloadAppStoreButton
                                    soon={false}
                                    className="icon-app_store"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    if (randomPass) {
        rowContent = (
            <div className="ph-alert ph-alert_info b-profile-box__twostep__alert">
                {intl.formatMessage(messages.randomPass)}
            </div>
        );
    }

    return (
        <div
            className={cn("b-profile-box__twostep", {
                "b-profile-box__twostep_random": randomPass
            })}
        >
            <div className="b-profile-box__security-field__header">
                {intl.formatMessage(messages.twoStepAuth)}
            </div>
            {rowContent}
        </div>
    );
};

const TwoStepAuthBlock = (props) => {
    const { randomPass } = props;
    const [twoStepEnabled, setTwoStepEnabled] = useState(false);

    useEffect(() => {
        const enabled = checkIs2faEnabled();

        /* Listen onSuccess2faDisable call to change state */
        if (enabled) {
            window.__GEMS.onSuccess2faDisable = (() => {
                setTwoStepEnabled(false);
                set2faEnabledFlag(false);
            });
        }

        setTwoStepEnabled(enabled);
    }, []);

    const component = useMemo(() => {
        switch (true) {
            case twoStepEnabled:
                return <EnabledAuthBlock />;
            default:
                return <DisabledAuthBlock randomPass={randomPass} />;
        }
    }, [twoStepEnabled]);

    return component;
};

export default TwoStepAuthBlock;
