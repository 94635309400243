import React from "react";

import withLang from "./components/withLang.jsx";
import useProfileSettings from "./useProfileSettings";

const App = () => {
    useProfileSettings();

    return null;
};

export default withLang(App);