import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import AccountNotReady from "./AccountNotReady.jsx";
import { isCorpUser, Backend } from "@vkplay/shared";

const translationTrans = defineMessages({
    description: {
        id: "translation.description",
        defaultMessage: "Чтобы начать транслировать игровой процесс или другой контент с помощью Игрового центра достаточно подключить аккаунт к одной из платформ ниже"
    },
    descriptionDemo: {
        id: "translation.description.demo",
        defaultMessage: "Чтобы начать транслировать игровой процесс или другой контент с помощью Игрового центра достаточно подключить аккаунт VK PLay Live"
    },
    title: {
        id: "translation.title",
        defaultMessage: "Выбор платформы"
    },
    custom: {
        id: "translation.custom",
        defaultMessage: "Ручная настройка"
    }
});

const redirectUrl = location.search.indexOf("redirect_to") > -1;

class ChannelsList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            buttonIsActive: "",
            accountError: false,
            unmounted: false
        };
    }

    componentDidMount () {
        this.checkAccountError();
        this.checkGCredirect();
    }

    componentDidUpdate (prevProps, prevState) {
        if (!prevProps.gcAuthCode && this.props.gcAuthCode) {
            this.onUpdGcAuthCode();
        }
    }

    checkGCredirect = () => {
        const search = new URLSearchParams(location.search);
        const { channelsList } = this.props;
        const searchChannel = search.get("redirect_to");

        if (searchChannel) {
            for (let channel in channelsList) {
                if (channelsList[channel].channel_type === searchChannel) {
                    location.href = channelsList[channel].redirect_url;
                }
            }
        }
    }

    onUpdGcAuthCode = () => {
        const code = this.props.gcAuthCode;

        if (code === "7501") {
            this.setState({
                accountError: true,
                unmounted: false
            });
            window.gc_set_window_size && window.gc_set_window_size(null, 450);
        }
        else {
            this.setState({
                unmounted: false
            });
        }
        this.props.resetAuthCode && this.props.resetAuthCode();
    }

    oAuthWindowOptions = (channel, w, h) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;

        return (
            `
      menubar=no, location=yes, status=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
        );
    }

    oAuthWindowOpen = (e, channelType, oAuthUrl) => {
        e && e.preventDefault();
        this.setState({ buttonIsActive: channelType });
        const { gamecenter } = this.props;
        const target = gamecenter ? "_self" : "oauth-dialog";

        const oAuthWindow = window.open(oAuthUrl, target, this.oAuthWindowOptions("streaming", "500", "700"));

        if (oAuthWindow && window.focus) oAuthWindow.focus();
        const timer = setInterval(function () {
            if (!gamecenter && oAuthWindow && oAuthWindow.closed) {
                clearInterval(timer);
                parent.location.reload();
            }
        }, 1000);
    }


    gcAuth = (channelType, url) => {
        this.setState({
            unmounted: true
        });
        window.__GEM.embedFinishBrowser("#translation-channels");

        window.gc_open_external_browser && window.gc_open_external_browser(`portal${url}`);
    }

    renderChannel = (channel) => {
        let { channel_type } = channel;

        if (channel_type === "M") {
            return null;
        }

        const channelListName = { "T": "twitch", "M": "mixer", "Y": "youtube", "L": "vkplay_live" };
        const { buttonIsActive } = this.state,
            { gamecenter } = this.props;
        const buttonClassName = (channelType) => (
            buttonIsActive === channelType ? "b-translation__channel_active" :
                !gamecenter ? "b-translation__channel_disabled" : ""
        );

        return (
            <button
                key={channel.channel_name}
                type="button"
                className={
                    `b-translation__channel b-translation__channel_${channelListName[channel_type]}
          ${buttonIsActive && buttonClassName(channel_type)}`
                }
                onClick={(e) => {
                    return gamecenter ? this.gcAuth(channel_type, channel.redirect_url_gc) :
                        this.oAuthWindowOpen(e, channel_type, channel.redirect_url);
                }}
            >
                {channelListName[channel_type]}
            </button>
        );
    }

    checkAccountError = () => {
        const error = localStorage.getItem("stream_error") || sessionStorage.getItem("stream_error");
        const search = new URLSearchParams(location.search);
        const searchError = search.get("error");

        if (error === "7501" || searchError === "7501") {
            this.setState({
                accountError: true
            }, () => {
                localStorage.removeItem("stream_error");
                sessionStorage.removeItem("stream_error"); // support for GC
            });
        }
    }

    onChangeStreamAccount = () => {
        const { channelsList, gamecenter } = this.props;
        const yt = channelsList.filter(item => item.channel_type.toLowerCase() === "y");

        if (yt.length) {
            this.setState({
                accountError: false
            });

            window.gc_set_window_size && window.gc_set_window_size(null, 270);

            gamecenter ? this.gcAuth("Y", yt[0]["redirect_url"]) :  this.oAuthWindowOpen(null, "Y", yt[0]["redirect_url"]);
        }
    }

    handleCheckVkLive = () => {
        this.setState({ buttonIsActive: "vkplay_live" });
        Backend.api_get("/account/streams/channel/L/refresh_channel/")
            .then(response => {
                if (response.status === "ok") {
                    this.setState({ buttonIsActive: "" });
                    this.props.setStreamReady(response);
                } else if (response.status === "fail") {
                    if (response.code === 7601 || response.code === 7602) {
                        this.setState({ accountError: true });
                    }
                }
            })
            .catch(error => {
                console.warn(error);
            });
    }

    showVKLiveBtn = (user) => {
        const allowedUsers = [177334401, 256922211, 122527423, 195922469];
        const allowedUser = allowedUsers.includes(user.user_id);

        return isCorpUser(user.login) || allowedUser;
    }

    render () {
        const { accountError, unmounted, buttonIsActive } = this.state;
        const { intl, channelsList, gamecenter, user } = this.props;

        const showVKPlayLive = user?.login && this.showVKLiveBtn(user);

        if (unmounted || redirectUrl) {
            return null;
        }

        if (gamecenter && accountError) {
            return <AccountNotReady onChangeStreamAccount={this.onChangeStreamAccount}
                isOpen={accountError}
                type={buttonIsActive}
                gamecenter={gamecenter}
                onClose={() => this.setState({ accountError: false, buttonIsActive: "" })}/>;
        }

        return (
            <div className="b-translation" ref={this.props.ref || null}>
                <div className="b-translation__help-text">
                    {intl.formatMessage(showVKPlayLive ? translationTrans.descriptionDemo : translationTrans.description)}
                </div>
                <div className="b-translation__content">
                    {showVKPlayLive ? (
                        <div className="b-translation__channels b-translation__channels_live">
                            <button
                                type="button"
                                className={
                                    `b-translation__channel b-translation__channel_vkplay_live 
                                ${buttonIsActive === "vkplay_live" && "b-translation__channel_active"}`
                                }
                                onClick={(e) => this.handleCheckVkLive()}
                            >
                                Подключить VK Play Live
                            </button>
                            <div className="b-translation__settings b-link" onClick={() => this.props.openManualSettings()}>
                                {intl.formatMessage(translationTrans.custom)}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="b-translation__caption">
                                <div className="b-translation__subtitle">{intl.formatMessage(translationTrans.title)}</div>
                                <div className="b-translation__settings b-link" onClick={() => this.props.openManualSettings()}>
                                    {intl.formatMessage(translationTrans.custom)}
                                </div>
                            </div>
                            <div className="b-translation__channels">
                                {channelsList.map(channel => this.renderChannel(channel))}
                            </div>
                        </>
                    )}
                </div>

                {accountError &&
                    <AccountNotReady onChangeStreamAccount={this.onChangeStreamAccount}
                        isOpen={accountError}
                        gamecenter={gamecenter}
                        type={buttonIsActive}
                        onClose={() => this.setState({ accountError: false, buttonIsActive: "" })}/>
                }
            </div>
        );
    }
}

export default injectIntl(ChannelsList, { withRef: true });
