import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import ajax from "superagent";
import { Backend, getGemHost } from "@vkplay/shared";

const translationCustomTrans = defineMessages({
    custom: {
        id: "translation.custom",
        defaultMessage: "Ручная настройка"
    },
    customUrl: {
        id: "translation.custom.url",
        defaultMessage: "URL сервера"
    },
    customKey: {
        id: "translation.custom.key",
        defaultMessage: "Секретный ключ трансляции"
    },
    customSave: {
        id: "translation.custom.save",
        defaultMessage: "Сохранить"
    },
    customCancel: {
        id: "translation.custom.cancel",
        defaultMessage: "Отмена"
    }
});

class ManualSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            translationUrl: "",
            translationKey: "",
            errorUrl: "",
            errorKey: ""
        };
    }

    componentDidMount() {
        this.getTranslationSettings();
    }

  getTranslationSettings = () => {
      Backend.get(`//${getGemHost("account")}/api/streams/channel/C/`)
          .then(result => {this.setState(this.setFormSettings(result));})
          .catch(error => {console.warn(error);});
  }

   setTranslationSettings = () => {
       ajax.put(`//${getGemHost("account")}/api/streams/channel/C/`)
           .set("Content-Type", "application/json")
           .set("X-JWTCSRFToken", window.__GEMS.token)
           .send(JSON.stringify({
               url: this.state.translationUrl,
               key: this.state.translationKey
           })
           )
           .withCredentials()
           .type("json")
           .then(response => response.ok && this.props.hasSuccessResult())
           .catch((error) => this.setState(this.displayFormError(error.response.body)));
   }

  displayFormError = (error) => ({
      errorUrl: error.url ? error.url[0] : "",
      errorKey: error.key ? error.key[0] : ""
  })

  setFormSettings = (data) => ({
      translationUrl: data.url ? data.url : "",
      translationKey: data.key ? data.key : ""
  })

  changeTranslationUrl = (e) => {
      this.setState({ translationUrl: e.target.value, errorUrl: "" });
  }

  changeTranslationKey = (e) => {
      this.setState({ translationKey: e.target.value, errorKey: "" });
  }

  handleSubmit = () => {
      const formIsField = this.state.translationUrl && this.state.translationKey;

      if (formIsField) {
          this.setTranslationSettings();
      }
  }

  render() {
      const { translationUrl, translationKey, errorUrl, errorKey } = this.state, { intl, gamecenter } = this.props;
      const buttonIsDisabled = !translationUrl || !translationKey || errorKey || errorUrl;

      return (
          <React.Fragment>
              {!gamecenter && <div className="b-popup__header"><h3>{intl.formatMessage(translationCustomTrans.custom)}</h3></div>}
              <div className="b-custom-settings">
                  <div className={`b-custom-settings__field ${!translationUrl || "is-filled"}`}>
                      <div className="b-custom-settings__input-wrapper">
                          <input
                              className="b-custom-settings__input"
                              id="translation-url"
                              type="text"
                              value={translationUrl}
                              onChange={this.changeTranslationUrl}
                              required
                          />
                          <label
                              htmlFor="translation-url"
                              className={`b-custom-settings__label ${!errorUrl || "b-custom-settings__label_error"}`}
                          >
                              {intl.formatMessage(translationCustomTrans.customUrl)}
                          </label>
                      </div>
                      <div className="b-custom-settings__error">{errorUrl && errorUrl}</div>
                  </div>
                  <div className={`b-custom-settings__field ${!translationKey || "is-filled"}`}>
                      <div className="b-custom-settings__input-wrapper">
                          <input
                              className="b-custom-settings__input"
                              id="translation-key"
                              type="password"
                              value={translationKey}
                              onChange={this.changeTranslationKey}
                              required
                          />
                          <label
                              htmlFor="translation-key"
                              className={`b-custom-settings__label ${!errorKey || "b-custom-settings__label_error"}`}
                          >
                              {intl.formatMessage(translationCustomTrans.customKey)}
                          </label>
                      </div>
                      <div className="b-custom-settings__error">{errorKey && errorKey}</div>
                  </div>
                  <div className="b-custom-settings__buttons">
                      <button type="button" className="b-custom-settings__submit b-custom-settings__button" onClick={this.handleSubmit} disabled={buttonIsDisabled}>
                          {intl.formatMessage(translationCustomTrans.customSave)}
                      </button>
                      <button type="button" className="b-custom-settings__cancel b-custom-settings__button" onClick={this.props.onClose}>
                          {intl.formatMessage(translationCustomTrans.customCancel)}
                      </button>
                  </div>
              </div>
          </React.Fragment>
      );
  }
}

export default injectIntl(ManualSettings);
