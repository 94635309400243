import React from "react";
import ReactDOM from "react-dom";

import Privacy from "./components/Privacy/Privacy.jsx";


const PrivacyApp = {
    init: function (props) {

        let visibilityContainer = document.getElementById("visibility");

        visibilityContainer && ReactDOM.render(<Privacy {...props} />, visibilityContainer);
    }
};



export default PrivacyApp;

