import React from 'react';
import { IntlProvider } from 'react-intl';
import withLang from '../withLang.jsx';
import DeleteContent from './DeleteContent.jsx';

const Delete = ({ messages, lang }) => {
    return (
        <IntlProvider locale={lang} messages={messages}>
            <DeleteContent />
        </IntlProvider>
    );
};

export default withLang(Delete);