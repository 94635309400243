import React from "react";
import cx from "classnames";

import styles from "./modules/radio.module.css";

const Radio = ({ id, className, children, disabled, ...restProps }) => {
    return (
        <div className={cx(styles.radioContainer, { [styles.disabled]: disabled }, className)}>
            <label className={styles.label}>
                <input
                    type="radio"
                    className={styles.input}
                    disabled={disabled}
                    {...restProps}
                />
                <span
                    className={styles.circle}
                />
                <span className={styles.children}>
                    {children}
                </span>
            </label>
        </div>
    );
};

export default Radio;