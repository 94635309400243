// ключи это origin в сессии sezam
export const platforms = {
    "fb": {
        "name": "Facebook",
        "url": "https://facebook.com",
        "password_url": "https://www.facebook.com/settings?tab=account&section=password",
    },
    "tw": {
        "name": "Twitter",
        "url": "https://twitter.com",
        "password_url": "https://twitter.com/settings/password",
    },
    "g": {
        "name": "Google",
        "url": "https://accounts.google.com",
        "password_url": "https://accounts.google.com/EditPasswd",
    },
    "p": {
        "name": "phone",
        "url": "",
        "password_url": `${location.origin}/password/reset/?continue=/profile/userinfo/&recaptcha=1&auth_change=1`
    },
    "e": {
        "name": "email",
        "url": "",
        "password_url": `${location.origin}/password/reset/?continue=/profile/userinfo/&recaptcha=1&auth_change=1`
    },
    "steam": {
        "name": "Steam",
        "url": "https://store.steampowered.com/account/",
        "password_url": "https://store.steampowered.com/account/",
    },
    "ps": {
        "name": "PlayStation Network",
        "url": "https://secure.eu.playstation.com/psn/mypsn/",
        "password_url": "https://account.sonyentertainmentnetwork.com/external/forgot-password!input.action",
    },
    "mailru": {
        "name": "Mail.ru",
        "url": "https://mail.ru/",
        "password_url": "https://id.mail.ru/security",
    },
    "vk": {
        "name": "vk.com",
        "url": "https://vk.com/",
        "password_url": "https://vk.com/settings",
    },
    "ok": {
        "name": "ok.ru",
        "url": "https://ok.ru/",
        "password_url": "https://ok.ru/settings",
    },
    "twitch": {
        "name": "twitch.tv",
        "url": "https://www.twitch.tv/",
        "password_url": "https://www.twitch.tv/settings/security",
    },
    "hitbox": {
        "name": "smashcast.tv",
        "url": "https://www.smashcast.tv",
        "password_url": "",
    },
    "xbox": {
        "name": "Xbox",
        "url": "https://account.xbox.com/ru-ru/Profile?xr=mebarnav&rtc=1",
        "password_url": "https://account.microsoft.com/security",
    },
    "st": {
        "name": "Steam",
        "url": "https://store.steampowered.com/account/",
        "password_url": "https://store.steampowered.com/account/",
    },
    "ig": {
        "name": "Instagram",
        "url": "",
        "password_url": "",
    },
    "psn": {
        "name": "PlayStation Network",
        "url": "https://secure.eu.playstation.com/psn/mypsn/",
        "password_url": "https://account.sonyentertainmentnetwork.com/external/forgot-password!input.action",
    },
    "dis": {
        "name": "Discord",
        "url": "",
        "password_url": "",
    },
    "xbx": {
        "name": "Xbox",
        "url": "",
        "password_url": "https://account.microsoft.com/security",
    },
    "apple": {
        "name": "Apple",
        "url": "https://appleid.apple.com/",
        "password_url": "https://iforgot.apple.com/password/verify/appleid",
    },
    "default": {
        "name": "Social Network",
        "url": "",
        "password_url": "",
    },
    "base": {
        "password_url": "https://e.mail.ru/settings/security",
    }
};

export const checkIs2faEnabled = () => {
    if (window.__GEMS && window.__GEMS.current_user && window.__GEMS.current_user.profile_fields) {
        return window.__GEMS.current_user.profile_fields.twostep === "1";
    }

    return false;
};

export const set2faEnabledFlag = (enabled) => {
    if (window.__GEMS && window.__GEMS.current_user && window.__GEMS.current_user.profile_fields) {
        window.__GEMS.current_user.profile_fields.twostep = enabled ? "1" : null;
    }
};

export const secondsToTime = (secs) => {
    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return {
        hours,
        minutes,
        seconds
    };
};

export const getFormattedTime = (secs) => {
    const time = secondsToTime(secs);

    if (time.hours !== 0 && time.hours.toString().length === 1) {
        time.hours = `0${time.hours}`;
    }

    if (time.minutes.toString().length === 1) {
        time.minutes = `0${time.minutes}`;
    }

    if (time.seconds.toString().length === 1) {
        time.seconds = `0${time.seconds}`;
    }

    return `${time.hours ? time.hours + ":" : ""}${time.minutes}:${time.seconds}`;
};