import React from "react";
import ReactDOM from "react-dom";

import Region from "./components/Region/Region.jsx";

const RegionApp = {
    init: () => {
        const container = document.getElementById("region");

        container && ReactDOM.render(<Region />, container);
    }
};

export default RegionApp;
