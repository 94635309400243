import * as React from "react";
import cx from "classnames";

import styles from "./modules/button.module.css";

const Button = ({ styling, className, children, icon, ...restProps }) => (
    <button {...restProps} className={cx(styles.default, styles[styling], className)}>
        {children}
    </button>
);

export default Button;
