import React, { Component } from "react";
import AvatarSettingsContent from "./AvatarSettingsContent.jsx";
import withLang from "./withLang.jsx";
import { IntlProvider } from "react-intl";

class AvatarSettings extends Component {
    state = {
        isOpen: this.props.isOpen,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen !== this.props.isOpen) {
            const banned = this.checkBan();

            if (banned) {
                document.getElementById("ban-avatar-popup").style.display = "block";
            } else {
                this.setState(() => ({
                    isOpen: this.props.isOpen,
                    activeTab: this.props.activeTab
                }));
            }
        }
    };

	checkBan = () => {
	    return window.taboo_avt;
	}

    onClose = () => {
        this.setState(() => ({ isOpen: false }));
        window.history.pushState({}, "", "?change_avatar=0");
    };

    render() {
        const { lang, messages, activeTab } = this.props;
        const { isOpen } = this.state;

        return (
            <IntlProvider messages={messages} locale={lang}>
                <AvatarSettingsContent
                    isOpen={isOpen}
                    onClose={this.onClose}
                    activeTab={activeTab}
                />
            </IntlProvider>
        );
    }
}

export default withLang(AvatarSettings);
