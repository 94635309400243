import React, { Component } from "react";
import { injectIntl } from "react-intl";
import cn from "classnames";
import { Backend, origin_by_login, isVkPlay, copyTextToClipboard } from "@vkplay/shared";

import LogoutConfirmPopup from "./LogoutConfirmPopup.jsx";
import TwoStepAuthBlock from "./TwoStepAuthBlock.jsx";
import TwoStepDisableWarning from "./TwoStepDisableWarning.jsx";
import LoginsList from "./AltLogin/LoginsList.jsx";

import { checkIs2faEnabled, platforms } from "./const";
import messages from "./SecurityMessages";
import { showNotify } from "../../useProfileSettings";

class SecurityContent extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLogoutConfirmVisible: false,
            mailerError: null,
            errorVerificationMsg: null,
            isTwoStepDisableWarningVisible: false,
            hideTwoStep: true
        };

        this.tickTimer = null;
        this.verifyEmailUrl = "/send_verification/";
    }

    componentDidMount () {
        if (this.props.user) {
            this.checkNeed2Step();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.user && this.props.user) {
            this.checkNeed2Step();
        }
    }

	checkNeed2Step = () => {
	    const { hideTwoStep } = this.state;
	    const needShow2Step = isVkPlay;

	    if (hideTwoStep && needShow2Step) {          // show 2fa
	        this.setState({
	            hideTwoStep: false
	        });
	    }
	}

    toggleLogoutConfirm = () => {
        this.setState({ isLogoutConfirmVisible: !this.state.isLogoutConfirmVisible });
    }

    logout = async () => {
        if (checkIs2faEnabled()) {
            this.setState(() => ({ isTwoStepDisableWarningVisible: true }));
        } else {
            try {
                await Backend.api_post("/account/logout/");

                if (window.gc_logout) {
                    window.gc_logout();
                } else {
                    window.location.reload();
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    getChangePasswordLinkParams = () => {
        const { user } = this.props;
        const params = { href: "", target: "_blank" };

        let origin = origin_by_login(user.login);

        params.href = platforms[origin] && platforms[origin]["password_url"];

        if (origin === "p" || origin === "e") {
            params.target = "_self";
        }

        if (!params.href) {
            params.href = platforms.base.password_url;
        }

        return params;
    }

    onVerifyClick = () => {
        Backend.post(this.verifyEmailUrl).then((response) => {
            if (response.status === "ok") {
                this.setState({
                    errorVerificationMsg: this.props.intl.formatMessage(messages.verifySended)
                });
            } else if (response.timeout) {
                this.setState({
                    errorVerificationMsg: response.error_message
                });

                setTimeout(() => {
                    this.setState({
                        errorVerificationMsg: null
                    });
                }, Number(response.timeout) * 1000);
            } else if (response.error) {
                this.setState({
                    mailerError: response.error_message
                });

                setTimeout(() => {
                    this.setState({
                        mailerError: null
                    });
                }, 20000);
            }
        }).catch(err => console.warn(err));
    }

    onCopyText = (text) => {
        const { intl } = this.props;

        copyTextToClipboard(text);
        showNotify({ text: intl.formatMessage(messages.copied) });
    }

    render () {
        const { user, intl } = this.props;
        const { isLogoutConfirmVisible, mailerError, errorVerificationMsg, isTwoStepDisableWarningVisible, hideTwoStep } = this.state;

        if (!user) {
            return null;
        }

        const origin = origin_by_login(user.login);
        const emailLogin = origin === "e" || origin === "mailru";
        const isMyGamesEmail = origin === "mygames";
        const emailValue = user ? (user.profile_fields.alt_email || user.login) : "";
        const emailVerified = user.verified === "1";

        const params = this.getChangePasswordLinkParams();
        const hidePassBlocks = window.gem_user_random_password;

        const PLATFORM_NAMES = {
            vk: "VK ID",
            ok: intl.formatMessage(messages.odnoklassniki),
            fb: "Facebook",
            tw: "Twitter",
            ds: "Discord",
            g: "Google",
            steam: "Steam",
            twitch: "Twitch",
            hitbox: "Hitbox",
            ps: "PlayStation",
            xbox: "Xbox",
            apple: "Apple",
            eg: "Epic Games Store",
            ns: "Nintendo Switch"
        };

        return (
            <>
                <h2 className="b-profile-box__heading">{intl.formatMessage(messages.header)}</h2>
                <div className="b-profile-box__content">
                    <div className="b-profile-box__block">

                        <div className="b-profile-box__security-field">
                            <div className={"b-profile-box__security-field__header"}>
                                <span>
                                    <span>{intl.formatMessage(messages.account)}</span>
                                </span>
                                {emailVerified ?
                                    <span className="formMsg formMsg_ok">
                                        {intl.formatMessage(messages.verified)}
                                    </span> :
                                    <span className="formMsg formMsg_error">
                                        {intl.formatMessage(messages.notVerified)}
                                    </span>
                                }
                            </div>
                            <div className="b-profile-box__security-field__descr">
                                <span>ID {user && user.user_id}</span>
                                <span className="b-profile-copy " onClick={() => this.onCopyText(user && user.user_id)} />
                            </div>
                        </div>

                        <div className="b-profile-box__security-field">
                            <div className="b-profile-box__row login">
                                <img src={require("../../../img/icons/icon_login.png")} className="icon-login" alt=""/>
                                {emailLogin ?
                                    <div className="b-profile-box__row__data">
                                        <div className="b-profile-box__row__name">
                                            <span>{intl.formatMessage(messages.login)}</span>
                                        </div>
                                        <div className={cn("b-profile-box__row__value", {
                                            "error": !emailVerified
                                        })}>
                                            <span className="b-profile-box__login-value">{emailValue ? " " + emailValue : ""}</span>
                                            <span className="b-profile-copy " onClick={() => this.onCopyText(emailValue)} />
                                            {!emailVerified &&
                                            <div className="b-profile-box__row-error">
                                                <img src={require("../../../img/icons/icon_warning.png")} className="icon-warning"
                                                    alt=""/>
                                                <div className="b-profile-box__row-error__data">
                                                    <span>{intl.formatMessage(messages.troubles)}</span>
                                                    {errorVerificationMsg ?
                                                        <span className="b-profile-box__row-error__action">
                                                            {errorVerificationMsg}
                                                        </span> :
                                                        mailerError ?
                                                            <p className={"error"}>{mailerError}</p> :
                                                            <a className="b-link b-profile-box__row-error__action"
                                                                onClick={this.onVerifyClick}>
                                                                {intl.formatMessage(messages.sendVerify)}
                                                            </a>
                                                    }
                                                </div>

                                            </div>
                                            }
                                        </div>
                                    </div>

                                    :
                                    <div className="b-profile-box__row__data">
                                        <div className="b-profile-box__row__name">
                                            <span>{intl.formatMessage(messages.login)}</span>
                                        </div>
                                        <div className="b-profile-box__row__value b-profile-box__login-value">
                                            {PLATFORM_NAMES[origin] || user.login}
                                            <span className="b-profile-copy " onClick={() => this.onCopyText(user.login)} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="b-profile-box__row pass">
                                <img src={require("../../../img/icons/icon_pass.png")} className="icon-password" alt=""/>
                                <div className="b-profile-box__row__data">
                                    <div className="b-profile-box__row__name">
                                        <span>{intl.formatMessage(messages.password)}</span>
                                    </div>
                                    {hidePassBlocks ?
                                        <a {...params} className="b-profile-box__set_pass b-link">{intl.formatMessage(messages.setPassword)}</a>
                                        :
                                        <>
                                            <div className="b-profile-box__row__value">
                                                <span className="b-profile-box__security-field__pass"/>
                                            </div>
                                            <div className="b-profile-box__row__action">
                                                {!isMyGamesEmail && <a {...params}
                                                    className="b-link">{intl.formatMessage(messages.change)}</a>}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="b-profile-box__security-field">
                            <a
                                className="b-profile-box__logout b-link"
                                onClick={this.toggleLogoutConfirm}
                            >
                                {intl.formatMessage(messages.logout)}
                            </a>
                        </div>

                        {user.profile_fields.password_leaked &&
                            <div className="b-profile-box__pass-leaked ph-alert ph-alert_warning">
                                {intl.formatMessage(messages.passwordLeaked)}
                            </div>
                        }

                        {!hideTwoStep &&
							<TwoStepAuthBlock randomPass={hidePassBlocks}/>
                        }

                        <LoginsList origin={origin} />

                        {isLogoutConfirmVisible &&
                            <LogoutConfirmPopup
                                login={user.login}
                                onLogout={this.logout}
                                onClose={this.toggleLogoutConfirm}
                            />
                        }

                        {isTwoStepDisableWarningVisible && (
                            <TwoStepDisableWarning
                                onClose={() => this.setState({ isTwoStepDisableWarningVisible: false })}
                            />
                        )}
                    </div>
                </div>
            </>
        );

    }
}


export default injectIntl(SecurityContent);
