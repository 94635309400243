import React, { Component } from "react";
import Popup from "../Popup.jsx";
import ManualSettings from "./ManualSettings.jsx";
import ChannelActive from "./ChannelActive.jsx";
import ChannelsList from "./ChannelsList.jsx";
import { Backend } from "@vkplay/shared";
import { IntlProvider } from "react-intl";
import withLang from "../withLang.jsx";


class Translation extends Component {
    constructor (props) {
        super(props);

        this.state = {
            channelReady: false,
            isOpen: false,
            isFetching: false,
            channelActive: {},
            channelsList: [],
            gcAuthCode: ""
        };

        window.ProfileTranslation = this; // global access
    }

    componentDidMount () {
        this.getTranslationData();
    }


    getTranslationData = () => {
        this.setState({ isFetching: true });
        Backend.api_get("/account/streams/channel/")
            .then(result => {this.getChannelActive(result);})
            .catch(error => {
                console.warn(error);
                if (this.props.gamecenter) {
                    window.gc_show_auth_window(location.href);
                    window.gc_close_window();
                }
            });
    }

    getChannelActive = (result) => {
        const channelActive = result.filter(channel => channel.channel_ready)[0];
        const channelsList = result.filter(channel => !channel.channel_ready);

        this.setState(channelActive ? this.onSetChannelActive(channelActive) : this.onSetChannelsList(channelsList));
    }

    onSetChannelActive = (channelActive) => ({
        isOpen: false,
        isFetching: false,
        channelReady: true,
        channelActive: channelActive
    })

    onSetChannelsList = (channelsList) => ({
        channelReady: false,
        isFetching: false,
        channelsList: channelsList
    })

    openPopup = () => {
        this.setState({ isOpen: true });
    }

    onClose = () => {
        this.setState({ isOpen: false });
    }

    onGcAuthCode = (code) => {
        const finishBrowserEl = document.getElementsByClassName("js-ph-gc-embed");

        if (finishBrowserEl.length) {
            finishBrowserEl[0].innerHTML = "";
        }

        if (code !== "7501") {
            this.getTranslationData();
        }
        this.setState({
            gcAuthCode: code
        });
    }

    resetAuthCode = () => {
        this.setState({
            gcAuthCode: ""
        });
    }

    setStreamReady = (data) => {
        this.setState(this.onSetChannelActive(data));
    }

    render () {
        const { isOpen, channelReady, isFetching, channelActive, channelsList, accountError, gcAuthCode } = this.state,
            { lang, messages, gamecenter } = this.props,
            langProps = { messages: messages };
        const customSettingsGamecenter = isOpen && gamecenter;
        const customSettingsPopup = isOpen && !gamecenter;
        const customSettings = (
            <ManualSettings hasSuccessResult={() => this.getTranslationData()} onClose={this.onClose}
                gamecenter={gamecenter} {...langProps}/>
        );

        return (
            <IntlProvider locale={lang} messages={messages}>
                {isFetching ? <div className="b-translation__loader"/> : (
                    customSettingsGamecenter ? customSettings : (
                        channelReady ? (
                            <ChannelActive
                                channelActive={channelActive}
                                deleteChannel={() => this.getTranslationData()}
                                {...langProps}
                                gamecenter={gamecenter}
                                openManualSettings={() => this.openPopup()}
                            />
                        ) : (
                            <ChannelsList
                                user={this.props.user}
                                getChannel={() => this.getTranslationData()}
                                channelsList={channelsList}
                                openManualSettings={() => this.openPopup()}
                                gcAuthCode={gcAuthCode}
                                resetAuthCode={this.resetAuthCode}
                                setStreamReady={this.setStreamReady}
                                {...langProps} gamecenter={gamecenter}
                            />
                        )
                    )
                )}
                {customSettingsPopup &&
                <Popup isOpen={true} onClose={this.onClose} translation>{customSettings}</Popup>}

            </IntlProvider>
        );
    }
}

export default withLang(Translation);
