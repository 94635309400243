import React, { useEffect, useMemo, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Backend } from "@vkplay/shared";
import { showNotify } from "../../useProfileSettings";

import withLang from "../withLang.jsx";
import DropdownSelector from "../DropdownSelector.jsx";

import "./privacy.css";

const messages = defineMessages({
    title: {
        id: "privacy.title",
        defaultMessage: "Приватность"
    },
    ask: {
        id: "privacy.ask",
        defaultMessage: "Кто может видеть мой профиль?"
    },
    save: {
        id: "avatar.save",
        defaultMessage: "Сохранить"
    },
    3: {
        id: "privacy.all",
        defaultMessage: "Все пользователи"
    },
    2: {
        id: "privacy.nobody",
        defaultMessage: "Никто"
    },
    1: {
        id: "privacy.friends",
        defaultMessage: "Только друзья"
    },
    messagesTitle: {
        id: "privacy.messages_title",
        defaultMessage: "Кто может писать мне личные сообщения?"
    },
    messagesLabel: {
        id: "privacy.messages_label",
        defaultMessage: "Выбрать"
    },
    descr: {
        id: "privacy.descr",
        defaultMessage: "Вы можете выбрать, насколько ваша учетная запись будет публичной или приватной"
    }
});

// 1 = visible for friends
// 2 = nobody
// 3 = visible for all
const privacyType = [3, 1, 2];

const Privacy = (props) => {
    const { formatMessage } = useIntl();
    const { session } = props;
    const { profile } = session || {};

    const [visibility, setVisibility] = useState(profile.visibility);
    const [messageSettings, setMessageSettings] = useState(null);

    const messageSettingsList = useMemo(() => {
        return [
            { label: formatMessage(messages[3]), value: true },
            { label: formatMessage(messages[1]), value: false }
        ];
    }, []);

    const privacySettingsList = useMemo(() => {
        return [
            { label: formatMessage(messages[3]), value: 3 },
            { label: formatMessage(messages[1]), value: 1 },
            { label: formatMessage(messages[2]), value: 2 },
        ];
    }, []);

    const findPrivacy = (num) => {
        return privacySettingsList.filter(item => item.value === num)[0];
    };

    const handleMessagesSettings = (data) => {
        if (data) {
            const storedValue = messageSettings;

            setMessageSettings(data.value);

            Backend.api_patch("/messenger/v1/user/profile", { isPublic: data.value, isJSON: true }).then(resp => {
                if (resp) {
                    showNotify();
                }
            }).catch(e => {
                setMessageSettings(storedValue);
                console.warn(e);
            });

        }
    };

    const handleVisibilitySettings = (data) => {
        if (data) {
            const storedValue = visibility;

            setVisibility(data.value);

            Backend.post("/profile/visibility/", { visibility: data.value }).then(resp => {
                if (resp.status === "ok") {
                    showNotify();
                }
            }).catch(e => {
                setVisibility(storedValue);
                console.warn(e);
            });
        }
    };

    const getMessageSettings = () => {
        Backend.api_get("/messenger/v1/user/profile").then(resp => {
            if (resp) {
                setMessageSettings(resp.isPublic);
            }
        }).catch(e => {
            console.warn(e);
        });
    };

    useEffect(() => {
        getMessageSettings();
    }, []);

    return (
        <>
            <h2 className="b-profile-box__heading">{formatMessage(messages.title)}</h2>
            <div className="b-profile-box__content">
                <p>{formatMessage(messages.descr)}</p>
                <div className="b-profile-box__block" >
                    <table className="b-privacy-table">
                        <tbody>
                            <tr>
                                <td>{formatMessage(messages.ask)}</td>
                                <td>
                                    <DropdownSelector selected={findPrivacy(visibility).label}
												  defaultLabel={formatMessage(messages.messagesLabel)}
												  list={privacySettingsList}
												  handleSelect={handleVisibilitySettings}
							    />
                                </td>
                            </tr>

                            <tr>
                                <td>{formatMessage(messages.messagesTitle)}</td>
                                <td>
                                    <DropdownSelector selected={messageSettings ? messageSettingsList[0].label : messageSettingsList[1].label}
												  defaultLabel={formatMessage(messages.messagesLabel)}
												  list={messageSettingsList}
												  handleSelect={handleMessagesSettings}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};


export default withLang(Privacy);
