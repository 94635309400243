import { defineMessages } from "react-intl";

export default defineMessages({
    showAll: {
        id: "show.all",
        defaultMessage: "Показать все",
    },
    hide: {
        id: "hide",
        defaultMessage: "Скрыть",
    },
    lawsTitle: {
        id: "laws.title",
        defaultMessage: "Юридические документы"
    },
    lawsDescription: {
        id: "laws.description",
        defaultMessage: "Получите подробную информацию о принятых вами юридических документах.",
    },
    lawAccepted: {
        id: "laws.law.accepted",
        defaultMessage: "Принято",
    },
    twoStepAuth: {
        id: "security.twostep",
        defaultMessage: "Двухэтапная аутентификация",
    },
    auth: {
        id: "security.twostep.auth",
        defaultMessage: "Аутентификатор",
    },
    inactive: {
        id: "security.twostep.auth.inactive",
        defaultMessage: "Неактивно",
    },
    cancel: {
        id: "security.twostep.cancel",
        defaultMessage: "Отмена",
    },
    twoStepAuthDescription: {
        id: "security.twostep.description",
        defaultMessage: "Для подключения скачайте приложение {platform} App в Google Play или App Store и следуйте инструкциям внутри приложения.",
    },
    enable: {
        id: "security.twostep.enable",
        defaultMessage: "Подключить",
    },
    disable: {
        id: "security.twostep.disable",
        defaultMessage: "Отключить",
    },
    randomPass: {
        id: "security.twostep.random_pass",
        defaultMessage: "Вы сможете дополнительно защитить аккаунт после установки пароля."
    },
    accountLevelProtection: {
        id: "account.level.protection",
        defaultMessage: "Настройте уровень защиты аккаунта",
    },
    fullProtection: {
        id: "full.protection",
        defaultMessage: "Полная защита (рекомендуется)",
    },
    fullProtectionDescription: {
        id: "full.protection.description",
        defaultMessage: "Подтверждение входа в аккаунт и важных действий",
    },
    onlyActions: {
        id: "only.actions",
        defaultMessage: "Только действия",
    },
    onlyActionsDescription: {
        id: "only.actions.description",
        defaultMessage: "Выбирайте этот вариант, если у вас подключена двухэтапная аутентификация в социальной сети",
    },
    errorPollTokenCancelled: {
        id: "only.actions.cancelled",
        defaultMessage: "Запрос был отклонен.",
    },
    internalError: {
        id: "only.actions.internal_error",
        defaultMessage: "Внутренняя ошибка. Пожалуйста, попробуйте позже"
    },
});
