import React from "react";
import { useIntl } from "react-intl";

import Timeago from "../TimeagoWrapper.jsx";

import messages from "../../services/Messages";

const LawDocument = ({ title, url, date, lang }) => {
    const intl = useIntl();

    return (
        <div className="b-profile-box__doc">
            <div className="b-profile-box__doc__header">
                <a
                    rel="noopener noreferrer"
                    className="b-profile-box__doc__header__link"
                    target="_blank"
                    href={url}
                >
                    {title}
                </a>
            </div>
            <div className="b-profile-box__doc__body">
                <span className="b-profile-box__doc__body__accepted">
                    {intl.formatMessage(messages.lawAccepted)}
                </span>
                <span className="b-profile-box__doc__body__date">
                    <Timeago date={date} lang={lang}/>
                </span>
            </div>
        </div>
    );
};

export default LawDocument;
