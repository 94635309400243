import React from 'react';
import { useIntl } from 'react-intl';
import Popup from '../../Popup.jsx';
import Button from '../../shared/Button.jsx';

import messages from '../SecurityMessages';

const UnbindConfirmPopup = ({ onUnbindSuccess, onClose }) => {
    const intl = useIntl();

    return (
        <Popup
            className="b-unbind-popup"
            onClose={onClose}
            isOpen
        >
            <div className="ph-form__heading">
                <div className="ph-form__logo" />
            </div>
            <div className="b-unbind-popup__content">
                <div className="b-unbind-popup__title">
                    {intl.formatMessage(messages.unbindConfirm)}
                </div>
                <div className="b-unbind-popup__action">
                    <Button onClick={onUnbindSuccess} styling="big">
                        {intl.formatMessage(messages.yes)}
                    </Button>
                    <Button onClick={onClose} styling="big">
                        {intl.formatMessage(messages.no)}
                    </Button>
                </div>
            </div>
        </Popup>
    );
};

export default UnbindConfirmPopup;