import React from 'react';
import ReactDOM from 'react-dom';
import Security from './components/Security/Security.jsx';


const SecurityApp = {
    init() {
        let securityContainer = document.getElementById('security1')
        securityContainer && ReactDOM.render(<Security /> , securityContainer)
    }
}

export default SecurityApp
