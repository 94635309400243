import React, { useEffect } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import withLang from './withLang.jsx';

import { STORAGE_USER_DELETED } from '../_utils';
import { showNotify } from '../useProfileSettings';
import messages from '../components/Delete/DeleteMessages';

const Auth = (props) => {
    const intl = useIntl();

    useEffect(() => {
        const isSignup = window.location.pathname.indexOf('/signup') > -1;

        const embedAuthForm = () => {
            if (isSignup) {
                window.__GEM?.embedSignup && window.__GEM.embedSignup('.js-embed');
            } else {
                window.__GEM?.embedLogin && window.__GEM.embedLogin('.js-embed');
            }
        }

        embedAuthForm();

        try {
            const isDeleted = localStorage.getItem(STORAGE_USER_DELETED);

            if (isDeleted) {
                showNotify({
                    text: intl.formatMessage(messages.inProgress),
                });
                localStorage.removeItem(STORAGE_USER_DELETED)
            }
        } catch (error) {
            console.warn('Error getting localStorage deleted', error);
        }
    }, []);

    return (
        <IntlProvider locale={props.lang} messages={props.messages}>
            <div className="container">
                <div className="b-wrapper">
                    <div className="b-embed js-embed" />
                </div>
            </div>
        </IntlProvider>
    );
};

export default withLang(Auth);
