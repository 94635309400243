import React from "react";
import { useIntl } from "react-intl";

const SoonHint = ({ className = "" }) => {
    const intl = useIntl();

    return (
        <span className={`b-translation-soon ${className}`}>
            <span className="b-translation-soon__text">
                {intl.locale === "ru" ? "скоро" : "soon"}
            </span>
        </span>

    );
};

export default SoonHint;