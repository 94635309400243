import React, { Component } from "react";
import { Portal } from "react-portal";
import cn from "classnames";
import BodyClassName from "react-body-classname";


class Popup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen || false
        };
    }

    closePopup = () => {
        this.setState({
            isOpen: false
        });

        this.props.onClose && this.props.onClose();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState(() => ({ isOpen: this.props.isOpen }));
        }

        if (this.props.avatarSettings) {
            let needOpen = this.props.isOpen;

            if (prevState.isOpen !== needOpen) {
                this.setState({
                    isOpen: needOpen
                });
            }
        }
    }

    render() {
        const {
            className,
            unsubscribeMulti,
            type,
            avatarSettings,
            translation,
            changeEmail,
            size,
            title,
            headerAlign,
            children,
            textAlign
        } = this.props;
        const { isOpen } = this.state;

        return (
            <BodyClassName className={isOpen ? "is-popup-shown" : ""}>
                <Portal>
                    <div className={cn("b-profile-popup", "b-popup", {
                        "b-popup_huge": size === "huge",
                        "b-popup_avatar-settings": avatarSettings,
                        "b-popup_translation": translation,
                        "b-popup_changeEmail": changeEmail,
                        "b-popup_unsubscribe-multi": unsubscribeMulti,
                        "b-popup_account-not-ready": type === "account-not-ready",
                        "b-popup_confirm": type === "confirmPopup",
                        [className]: className
                    })} style={{ display: isOpen ? "block" : "none" }}>
                        <div className="b-popup__wrapper">
                            <div className="b-popup__overlay" onClick={this.closePopup} />
                            <div className={cn("b-popup__content", {
                                "b-popup__content_center": textAlign === "center"
                            })}>
                                <button className="b-popup__close" onClick={this.closePopup} />
                                {title && (
                                    <div className={cn("b-popup__header", {
                                        "b-popup__header_align-left": headerAlign === "left"
                                    })}>
                                        <h3>
                                            {title}
                                        </h3>
                                    </div>
                                )}

                                {children}
                            </div>
                        </div>
                    </div>
                </Portal>
            </BodyClassName>
        );
    }
}

export default Popup;
