import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Backend, getGemHost } from "@vkplay/shared";
import "./region.css";
import { iso31661, iso31662 } from "iso-3166";

const messages = defineMessages({
    title: {
        id: "region.title", defaultMessage: "Region"
    }, undefined: {
        id: "region.undefined", defaultMessage: "We couldn’t identify your region"
    }, based: {
        id: "region.based", defaultMessage: "Based on your IP-address"
    }, description: {
        id: "region.description",
        defaultMessage: "The region setting influences the content you see on MY.GAMES, the displayed currency, and the applicable taxes. The region may be updated after a purchase depending on the payment method used."
    }, contact: {
        id: "region.contact", defaultMessage: "If you wish to change the region, please contact:"
    }, unknown: {
        id: "region.unknown", defaultMessage: "Unknown"
    }
});

const RegionContent = () => {
    const intl = useIntl();
    const [region, setRegion] = useState({});

    const isDisputedTerritory = (countryCode, regionCode) => {
        return countryCode === "UA" && ["09", "14", "23", "43", "65"].includes(regionCode);
    };

    const formatRegion = ({ countryName, regionName, countryCode, regionCode }) => {
        if (!countryName) {
            return intl.formatMessage(messages.unknown);
        }
        if (!regionName) {
            return countryName;
        }
        if (isDisputedTerritory(countryCode, regionCode)) {
            return regionName;
        }

        return `${countryName}, ${regionName}`;
    };

    const formatFlagPic = (countryCode, regionCode) => {
        if (isDisputedTerritory(countryCode, regionCode)) {
            return null;
        }
        if (region?.countryName) {
            return `https://flagsapi.com/${region?.countryCode}/flat/64.png`;
        }

        return require("../../../img/svg/unknown-region.svg");
    };

    const formatMessage = (countryName) => {
        if (countryName) {
            return intl.formatMessage(messages.based);
        }

        return intl.formatMessage(messages.undefined);
    };

    const fetchRegion = useCallback(() => {
        Backend.get(`https://${getGemHost("api")}/myregion/`)
            .then(result => {
                const countryName = iso31661.find(x => x.alpha2 === result?.country_code)?.name;
                const regionName = iso31662.find(x => x.code === `${result?.country_code}-${result?.region_code.toString()}`)?.name;

                setRegion({
                    countryName: countryName,
                    regionName: regionName,
                    countryCode: result?.country_code,
                    regionCode: result?.region_code
                });
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        fetchRegion();
    }, [fetchRegion]);

    return (<>
        <div className="b-profile-box__content">
            <div className="b-profile-box__block">
                <div className="b-profile-box__container">
                    <div className="b-profile-box__left">
                        <h2 className="b-profile-box__heading b-region__heading">{intl.formatMessage(messages.title)}</h2>
                        <div className="b-profile-box__footer">
                            <div className="b-profile-box__userRegion">
                                {formatFlagPic(region.countryCode, region.regionCode) !== null &&
                                    <img className="b-profile-box__flag"
                                        src={formatFlagPic(region.countryCode, region.regionCode)}
                                        alt=''/>}
                                <div className="b-profile-box__country">
                                    {formatRegion(region)}
                                </div>
                            </div>
                            <div
                                className="b-profile-box__hint">{formatMessage(region?.countryName)}</div>
                        </div>
                    </div>
                    <div className="b-profile-box__divider"/>
                    <div className="b-profile-box__right">
                        <div className="b-profile-box__desc">
                            {intl.formatMessage(messages.description)}
                        </div>
                        <div className="b-profile-box__support">
                            <div className="b-profile-box__contact">{intl.formatMessage(messages.contact)}</div>
                            <a className="b-profile-box__link" href="https://support.my.games/">MY.GAMES Support</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default RegionContent;
