import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import cn from "classnames";
import { Backend, origin_by_login } from "@vkplay/shared";

import Popup from "./Popup.jsx";
import WithScroll from "./withScroll.jsx";
import { showNotify } from "../useProfileSettings";
import ChangeEmailPopup from "./ChangeEmailPopup.jsx";
import { getQueryVariable, scrollTo } from "../_utils.js";
import { findDOMNode } from "react-dom";

const urls = {
    subsriptions: "/api/subscriptions/",
    add_email: "/api/subscriptions/change_alt_email/"
};

const newsletterTrans = defineMessages({
    boxHeader: {
        id: "newsletter.box.header",
        defaultMessage: "Почтовые рассылки"
    },
    settings: {
        id: "newsletter.settings",
        defaultMessage: "Настроить"
    },
    description: {
        id: "newsletter.description",
        defaultMessage: "Вы можете подписаться на рассылки от проектов, в которых вы зарегистрированы. Письма буду приходить на почту"
    },
    dropdown: {
        id: "newsletter.dropdown",
        defaultMessage: "Выберите проект из списка"
    },
    subscribeBtn: {
        id: "newsletter.subscribe",
        defaultMessage: "Подписаться"
    },
    newsletterType: {
        id: "newsletter.newsletterType",
        defaultMessage: "Типы рассылок"
    },
    selectAll: {
        id: "newsletter.selectAll",
        defaultMessage: "Выбрать все"
    },
    noSubscribes: {
        id: "newsletter.noSubscribes",
        defaultMessage: "У вас пока нет подписок на рассылки. Выберите проект, по которому хотите получать свежие новости."
    },
    yourSubscribes: {
        id: "newsletter.yourSubscribes",
        defaultMessage: "Ваши подписки"
    },
    saveChanges: {
        id: "newsletter.saveChanges",
        defaultMessage: "Сохранить изменения"
    },
    unsubscribe: {
        id: "newsletter.unsubscribe",
        defaultMessage: "Отписаться"
    },
    cancel: {
        id: "newsletter.cancel",
        defaultMessage: "Отмена"
    },
    unsubscribeHeader: {
        id: "newsletter.unsubscribeHeader",
        defaultMessage: "Отписаться от рассылки"
    },
    unsubscribeText: {
        id: "newsletter.unsubscribeText",
        defaultMessage: "Вы больше не сможете получать спецпредложения, свежие новости и полезные истории по проекту"
    },
    subscribeSuccess: {
        id: "newsletter.subscribeSuccess",
        defaultMessage: "Подписки успешно обновлены!"
    },
    confirm: {
        id: "newsletter.confirm",
        defaultMessage: "Понятно"
    },
    error: {
        id: "newsletter.error",
        defaultMessage: "Ошибка. Повторите попытку."
    },
    awaitText: {
        id: "newsletter.await",
        defaultMessage: "Ожидает подтверждения"
    },
    awaitPopupHeader: {
        id: "newsletter.await.popupHeader",
        defaultMessage: "Подписка ожидает подтверждения"
    },
    awaitPopupContent: {
        id: "newsletter.await.popupContent",
        defaultMessage: "На адрес электронной почты: <span>{mail}</span>, было отправлено письмо с подтверждением. Чтобы начать получать рассылку, перейдите по ссылке из письма",
    },
    resend: {
        id: "newsletter.await.resend",
        defaultMessage: "Отправить повторно"
    },
    awaitSended: {
        id: "newsletter.await.sended",
        defaultMessage: "Повторное подтверждение отправлено"
    },
    awaitError: {
        id: "newsletter.await.error",
        defaultMessage: "Произошла ошибка. Попробуйте еще раз"
    },
    countdownMessage: {
        id: "newsletter.await.countdown",
        defaultMessage: "Письмо с подтверждением может быть отправлено через {second} секунд"
    },
    doubleOptInHeader: {
        id: "newsletter.doubleoptin.header",
        defaultMessage: "Подписка оформлена!"
    },
    doubleOptInText: {
        id: "newsletter.doubleoptin.text",
        defaultMessage: "Рассылка по игре {game} будет приходить на email: <span>{email}</span> "
    },
    doubleOptInUnsubscribe: {
        id: "newsletter.doubleoptin.unsubscribe",
        defaultMessage: "Отменить подписку можно в настройках профиля"
    },
    verifyHeader: {
        id: "newsletter.verify.header",
        defaultMessage: "Подтвердите email"
    },
    verifyText: {
        id: "newsletter.verify.text",
        defaultMessage: "Перейдите по ссылке в письме, которое мы отправили вам по адресу:  <span>{mail}</span>, чтобы подтвердить адрес электронной почты"
    },
    waitVerify: {
        id: "newsletter.verify.wait",
        defaultMessage: "Ожидает подтверждения почты"
    },
    approveEmail: {
        id: "newsletter.email.approve",
        defaultMessage: "Подтвердить email"
    },
    descriptionNoEmail: {
        id: "newsletter.email.noEmail",
        defaultMessage: "Вы можете подписаться на рассылки от проектов, в которых вы зарегистрированы. Для этого введите email, на который хотите получать рассылки, в поле ниже."
    },
    emailDefaultError: {
        id: "newsletter.email.defaultError",
        defaultMessage: "Неверный email"
    },
    backendEmailError: {
        id: "newsletter.email.backendError",
        defaultMessage: "Не удалось обновить email"
    },
    emailIncorrect: {
        id: "newsletter.email.incorrect",
        defaultMessage: "Не удалось отправить письмо, проверьте корректность написания электронного адреса"
    },
    verifyCheckEmail: {
        id: "newsletter.verify.checkEmail",
        defaultMessage: "Email не подтверждён, проверьте почту."
    },
    sendVerify: {
        id: "newsletter.verify.send",
        defaultMessage: "Отправить подтверждение еще раз"
    },
    change: {
        id: "security.change",
        defaultMessage: "Изменить"
    },
    munsTitle: {
        id: "multi.unsubscribe.title",
        defaultMessage: "Отмена подписки"
    },
    munsDone: {
        id: "multi.unsubscribe.done",
        defaultMessage: "Вы отписались от рассылок по проекту <strong>{project}</strong>"
    },
    munsSeveral: {
        id: "multi.unsubscrube.several",
        defaultMessage: "Мы нашли несколько ваших аккаунтов, к которым привязан этот email"
    },
    munsCondition: {
        id: "multi.unsubscribe.condition",
        defaultMessage: "Чтобы отписаться от рассылок проекта <strong>{project}</strong> другим аккаунтом -  необходимо авторизоваться им и перейти во вкладку \"Управление рассылками\" в настройках профиля."
    },
    close: {
        id: "app.close",
        defaultMessage: "Закрыть"
    }
});

class NewsletterContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            socAuth: null,
            projects: [],
            projectIdList: [],
            subscribed: null,
            formattedProjects: null,
            registeredEdit: null,
            selectedCategories: null,
            subscribedEdit: null,
            changedCategories: null,
            checkAll: false,
            showSubscribeOk: false,
            showUnsubscribe: false,
            showAwaitPopup: false,
            showDoubleOptInOk: false,
            showChangeEmailPopup: false,
            showConfirm: false,
            doubleOptIn: "",
            awaitEdit: null,
            currentType: "",
            unsubscribeId: "",
            openDropdown: false,
            emailInput: "",
            emailError: false,
            emailVerifyError: false,
            unsubscribeMultiPopup: false
        };

        this.awaitTimers = {};
        this.emailVerifyTimeout = null;
        this.projectList = null;
        this.inputEmail = null;
        this.defaultEmailError = this.props.intl.formatMessage(newsletterTrans.emailDefaultError);
        this.backendEmailError = this.props.intl.formatMessage(newsletterTrans.backendEmailError);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.user != prevProps.user) {
            this.setState({
                user: prevProps.user
            });
        }
    }


    componentDidMount() {
        this.getSubsciptions();
        window.addEventListener("click", this.handleClickOutside);

    };

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClickOutside);
    }

    getSubsciptions = () => {
        Backend.get(urls.subsriptions).then(data => {
            if (data.error || data.status === 'fail') {
                return false;
            }

            if (!data.projects?.length) {
                const menuItem = document.querySelector("[data-alias=newsletter]");

                menuItem.style.display = "none";
            }

            this.setState({
                projects: data.projects || []
            }, this.formatProjects);
        }).catch(error => console.warn(error));
    };

    formatProjects = () => {
        let { projects } = this.state,
            formattedList = {},
            projectIdList = [];

        projects.forEach(item => {
            formattedList[item.project.ext_id] = item;
            formattedList[item.project.ext_id].dataTime = Date.now();
            projectIdList.push(item.project.ext_id);
        });

        this.setState({
            formattedProjects: formattedList,
            projectIdList: projectIdList
        }, () => {
            this.checkSubscribed();
            this.checkProject();
            this.checkDoubleOptInPopup();
        });
    }

    checkSubscribed = () => {
        let subscribed = false,
            projects = this.state.formattedProjects,
            subscribedCount = 0;

        this.state.projectIdList.forEach(name => {
            if (projects[name].is_subscribed) {
                subscribed = true;
                subscribedCount += 1;
            }
        });

        this.setState({
            subscribed: subscribed,
            subscribedCount: subscribedCount
        });

    }

    handleProjectSelect = (id) => {
        let choosenProjectId = id,
            selectedProject;

        selectedProject = this.state.formattedProjects[choosenProjectId];

        this.setState({
            registeredEdit: selectedProject,
            checkAll: false
        });
    }

    checkProject = () => {
        let qs = window.location.search;

        if (qs) {
            let clientId = getQueryVariable("client_id"),
                project = clientId ? this.state.formattedProjects[clientId] : null;

            if (project && project.is_subscribed) {
                this.onChangeClick(project.project.ext_id);
                setTimeout(() => {
                    scrollTo(document.getElementById("newsletter"));
                }, 200);
            }
        }
    }

    onChangeClick = (ext_id) => {
        let project = this.state.formattedProjects[ext_id],
            isEdit = this.state.subscribedEdit;

        if (isEdit && this.state.subscribedEdit.project.ext_id === ext_id) {
            this.setState({
                subscribedEdit: null
            });

            return false;
        }

        this.setState({
            subscribedEdit: project
        });
    }

    onAwaitClick = (ext_id) => {

        this.setState({
            awaitEdit: ext_id,
            showAwaitPopup: true
        });
    }

    onVerifyClick = () => {
        const { intl } = this.props;

        Backend.get("/api/subscriptions/send_verify_alt_email/").then(resp => {
            if (resp.status === "fail" && resp.timeout) {
                this.setState({
                    emailVerifyError: intl.formatMessage(newsletterTrans.countdownMessage, { second: resp.timeout || 300 })
                });
                this.emailVerifyTimeout && clearTimeout(this.emailVerifyTimeout);

                this.emailVerifyTimeout = setTimeout(() => {
                    this.setState({
                        emailVerifyError: false
                    });
                }, (resp.timeout || 300) * 1000);

                return false;
            }

            this.setState({
                showConfirm: true,
                emailVerifyError: false
            });
        }).catch(err => {
            console.warn(err);
        });

    }

    cancelChange = () => {
        this.setState({
            subscribedEdit: null
        });

    }

    showConfirm = (project) => {
        this.setState({
            showUnsubscribe: true,
            unsubscribeId: project.project.ext_id
        });
    }

    unsubscribe = (projectId, opts = { withConfirm: false }) => {
        let project = { ...this.state.formattedProjects[projectId] },
            data = {};

        data.client_id = project.project.ext_id;
        data.profile_categories = [];

        if (opts.withConfirm) {
            this.showConfirm(project);

            return false;
        }
        else {
            this.closePopup();
        }

        Backend.post(urls.subsriptions, data).then(data => {
            project.is_subscribed = false;
            project.subscribed_categories = [];
            showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.subscribeSuccess) });
            this.setState({
                // showSubscribeOk: true,
                subscribeEdit: null,
                formattedProjects: {
                    ...this.state.formattedProjects,
                    [projectId]: project
                }
            }, () => {
                this.unsubscribeMulti(project.project.ext_id);
                this.checkSubscribed();
            });
        }).catch(error => console.warn(error));
    }

    unsubscribeMulti = (ext_id) => {
        Backend.api_get("/account/subscriptions/another_profiles/", { client_id: ext_id }).then(resp => {
            if (resp.profiles && resp.profiles.length) {
                this.setState({
                    unsubscribeMultiPopup: {
                        project: ext_id,
                        profiles: resp.profiles
                    }
                });
            }
        }).catch(console.warn);
    }

    subscribe = (e) => {
        let selectType = e.target.dataset.view,
            all = e.target.dataset.all,
            data = {},
            project;

        if (selectType === "registered") {
            project = { ...this.state.registeredEdit };
        }
        else {
            project = { ...this.state.subscribedEdit };
            selectType = "subscribed";
        }

        if (selectType === "subscribed" && !project.subscribed_categories.length) {
            this.unsubscribe(project.project.ext_id, {
                withConfirm: true,
                type: selectType
            });

            return false;
        }

        if (all) {
            project.project.categories.forEach(item => {
                if (project.subscribed_categories.includes(item.id)) {
                    return false;
                }
                project.subscribed_categories.push(item.id);
            });
        }

        if (selectType === "registered" && !project.subscribed_categories.length) {
            return false;
        }

        data.client_id = project.project.ext_id;
        data.profile_categories = project.subscribed_categories;


        Backend.post(urls.subsriptions, data).then(data => {

            if (data.error) {
                showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.error) });

                return false;
            }
            project.is_subscribed = true;

            if (project.project.send_policy === "C") { // set that letter has been sended
                project.is_confirmation_letter_sended = true;
                project.confirmation_retry_time = 300;
            }

            // document.querySelector('#newsletter .js-ph-select').classList.remove('ph-select_has-selected');
            // document.querySelector('#newsletter .js-select-default').innerHTML = '';

            showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.subscribeSuccess) });
            this.setState({
                showConfirm: project.project.send_policy === "C" && !project.is_confirmed,
                subscribedEdit: null,
                registeredEdit: null,
                formattedProjects: {
                    ...this.state.formattedProjects,
                    [project.project.ext_id]: project
                }
            }, this.checkSubscribed);
        }).catch(error => console.warn(error));
    }

    selectAll = (e) => {
        let checked = e.target.checked,
            { project }  = this.state.registeredEdit,
            allCategories = project.categories.map(item => {
                return item.id;
            });

        this.setState({
            registeredEdit: {
                ...this.state.registeredEdit,
                subscribed_categories: checked ? allCategories : []
            },
            checkAll: e.target.checked
        });
    }

    selectCategory = (e) => {
        const selectType = e.target.dataset.view,
            selectCategoryId = Number(e.target.dataset.category);
        let subscribedCategory = selectType === "registered" ?
                this.state.registeredEdit.subscribed_categories.slice() :
                this.state.subscribedEdit.subscribed_categories.slice()
            , updSubscribedCategory;

        if (!subscribedCategory.includes(selectCategoryId)) {
            updSubscribedCategory = [...subscribedCategory, selectCategoryId];
        }
        else {
            subscribedCategory.splice(subscribedCategory.indexOf(selectCategoryId), 1);
            updSubscribedCategory = subscribedCategory ? [...subscribedCategory] : [];
        }

        if (selectType === "registered") {
            let project = this.state.registeredEdit;

            this.setState({
                registeredEdit: {
                    ...this.state.registeredEdit,
                    subscribed_categories: updSubscribedCategory,
                },
                checkAll: updSubscribedCategory.length === project.project.categories.length
            });
        }
        else { // changed
            this.setState({
                subscribedEdit: {
                    ...this.state.subscribedEdit,
                    subscribed_categories: updSubscribedCategory
                }
            });
        }
    }

    closePopup = () => {
        this.setState({
            showSubscribeOk: false,
            showUnsubscribe: false,
            showAwaitPopup: false,
            showDoubleOptInOk: false,
            showConfirm: false,
            showChangeEmailPopup: false,
            unsubscribeMultiPopup: false,
            emailError: ""
        });

        if (location.search.indexOf("subscribe") != -1) {
            this.clearSearchParam("subscribe");
        }
    }

    clearSearchParam = (param) => {
        const dataFields = location.search.substr(1).split("&");
        let searchStr = dataFields.filter(item => {
            return item.indexOf(param) === -1;
        }).join("&");

        searchStr.length ?
            history.pushState({}, "", `${location.origin}${location.pathname}?${searchStr}`) :
            history.pushState({}, "", `${location.origin}${location.pathname}`);
    }

    checkTimer = (project) => {
        let retryTime = project.confirmation_retry_time * 1000,
            { dataTime } = project,
            now = Date.now(),
            counterTime = now - dataTime;

        if (retryTime > counterTime) {
            return (retryTime - counterTime) / 1000;
        }

        return false;
    }

    setTimer = (projectId, count) => {
        this.awaitTimers = {
            [projectId]: {
                count: count,
                timer: setInterval(() => {
                    if (this.awaitTimers[projectId].count > 0) {
                        this.awaitTimers[projectId].count = this.awaitTimers[projectId].count - 1;
                    } else {
                        clearInterval(this.awaitTimers[projectId].timer);
                        this.awaitTimers[projectId] = {};
                    }
                }, 1000)
            }
        };

    }

    sendAwait = (id) => {
        let subsribedProjectEdit = this.state.subscribedEdit ? this.state.subscribedEdit.project.ext_id === id ? this.state.subscribedEdit : null : null;
        let project = subsribedProjectEdit ? subsribedProjectEdit : this.state.formattedProjects[id],
            data = {},
            { is_confirmation_letter_sended, is_confirmed, subscribed_categories } = project;

        if (this.awaitTimers[id] && this.awaitTimers[id].count > 0) {  // deny additional send if timer
            this.closePopup();
            showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.countdownMessage, { second: this.awaitTimers[id].count }) });

            return false;
        }

        if (project.confirmation_retry_time > 0) { // check initial timer from request
            let timer = this.checkTimer(project);

            if (timer && timer > 0) {
                this.setTimer(id, parseInt(timer));
                this.closePopup();
                showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.countdownMessage, { second: this.awaitTimers[id].count }) });

                return false;
            }
        }

        if (!project.subscribed_categories.length) {
            this.closePopup();

            return false;
        }

        data.client_id = project.project.ext_id;
        data.profile_categories = project.subscribed_categories;
        if (!is_confirmed && project.project.send_policy === "C" && subscribed_categories.length && !is_confirmation_letter_sended) {
            data.send_policy = project.project.send_policy;
        }

        Backend.post(urls.subsriptions, data).then(data => {
            if (data.status === "ok") {

                this.setTimer(id, 300);
                this.closePopup();
                showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.awaitSended) });
                this.setState({
                    // showSubscribeOk: true,
                    subscribedEdit: null,
                    registeredEdit: null,
                    awaitEdit: null,
                    formattedProjects: {
                        ...this.state.formattedProjects,
                        [project.project.ext_id]: project
                    }
                });

            } else {
                this.closePopup();
                showNotify({ type: "newsletter", text: this.props.intl.formatMessage(newsletterTrans.awaitError) });
            }
        }).catch(error => console.warn(error));
    }

    checkDoubleOptInPopup = () => {
        const search = location.search;

        if (search.indexOf("subscribe") != -1) {
            const data = {};
            const dataFields = location.search.substr(1).split("&");

            dataFields.forEach(item => {
                let dataField = item.split("=");

                data[dataField[0]] = dataField[1];
            });

            setTimeout(() => {
                scrollTo(document.getElementById("newsletter"));
            }, 200);

            data["subscribe"] && this.setState({
                doubleOptIn: this.state.formattedProjects[data.subscribe].project.name,
                showDoubleOptInOk: true
            });
        }
    }

    handleDropdown = () => {
        let opened = this.state.openDropdown;

        this.setState({
            openDropdown: !opened
        });
    }

    handleClickOutside = (e) => {
        let opened = this.state.openDropdown;

        if (opened && !this.projectList.contains(e.target)) {
            this.setState({
                openDropdown: false
            });
        }
    }

    handleEmailInput = (e) => {
        this.setState({
            emailInput: e.target.value,
            emailError: false
        });
    }

    checkEmailValidity = () => {
        let valid = false;
        let email = this.state.emailInput;

        if (email.indexOf("@") > -1 && email.length > 4) {
            valid = true;
        }

        return valid;
    }

    onAddEmail = ({ email }) => {
        const valid = email ? true : this.checkEmailValidity();

        if (valid) {
            Backend.post(urls.add_email, { email: email || this.state.emailInput }).then(resp => {
                if (resp.error || resp.status === "fail") {
                    let { code } = resp;
                    let { intl } = this.props;

                    switch (code) {
                        case 7110:
                            this.setState({
                                emailError: intl.formatMessage(newsletterTrans.countdownMessage, { second: resp.timeout || 300 })
                            });
                            break;
                        case 7111:
                            this.setState({
                                emailError: intl.formatMessage(newsletterTrans.emailIncorrect)
                            });
                            break;
                        case 7112:
                            this.setState({
                                emailError: intl.formatMessage(newsletterTrans.emailDefaultError)
                            });
                            break;
                        default:
                            this.setState({
                                emailError: /*resp.detail || resp.msg || resp.email[0] ||*/ this.backendEmailError
                            });
                    }

                    return false;
                }


                this.setState({
                    emailForVerification: email || this.state.emailInput,
                    showChangeEmailPopup: false

                }, () => {
                    this.setState({
                        showConfirm: true
                    });
                });
            }).catch(err => {
                const error = err.response && err.response.body;

                if (error && error.status === "fail") {
                    this.setState({
                        emailError: error.email[0] || this.backendEmailError
                    });
                }
            });
        }
        else {
            this.setState({
                emailError: this.defaultEmailError
            });
        }
    }

    showChangeEmail = () => {
        this.setState({
            showChangeEmailPopup: true
        });
    }

    render() {
        let user = this.state.user;

        if (!user) {
            return null;
        }

        let {
                formattedProjects,
                projectIdList,
                projects,
                registeredEdit,
                openDropdown,
                emailError,
                subscribedCount,
                emailForVerification,
                unsubscribeMultiPopup,
                emailVerifyError
            }
             = this.state,
            unsubscribeType = registeredEdit && registeredEdit.project.unsubscribe_type,
            unsubscribeTypeShort = (unsubscribeType === "A" || unsubscribeType === "B") ? true : false,
            { intl } = this.props,
            hasEmail = emailForVerification ? true : user ? user.profile_fields.alt_email : false,
            emailVerified = user && user.profile_fields.alt_email_verified === "1" && !emailForVerification,
            origin = origin_by_login(user.login),
            canChange = origin !== "mailru" && origin !== "g" && origin !== "e";

        if (!projects.length) {
            return null;
        }

        return (
            <>
                <h2 className="b-profile-box__heading" >
                    {intl.formatMessage(newsletterTrans.boxHeader)}
                </h2>
                <div className="b-profile-box__content">
                    <p className="newsletter-descr">
                        {user.profile_fields.alt_email ?
                            intl.formatMessage(newsletterTrans.description) :
                            intl.formatMessage(newsletterTrans.descriptionNoEmail)
                        }
                    </p>

                    {hasEmail ?
                        <div className="b-profile-box__row email">
                            <img src={require("../../img/icons/icon_email.png")} className="icon-email" alt=""/>
                            <div className="b-profile-box__row__data">
                                <div className="b-profile-box__row__name">
                                    <span>Email</span>
                                </div>
                                <div className={cn("b-profile-box__row__value", "b-profile-box__row__value_email", {
                                    "error": !emailVerified
                                })}>
                                    <span>
                                        {emailForVerification ? emailForVerification :
                                            user ? user.profile_fields.alt_email : ""
                                        }
                                    </span>
                                    {!emailVerified &&
                                        <div className="b-profile-box__row-error">
                                            <img src={require("../../img/icons/icon_warning.png")} className="icon-warning"
                                                alt=""/>
                                            <div className="b-profile-box__row-error__data">
                                                <span>
                                                    {intl.formatMessage(newsletterTrans.verifyCheckEmail)}</span>
                                                <a className="b-link b-profile-box__row-error__action"
                                                    onClick={this.onVerifyClick}>
                                                    {intl.formatMessage(newsletterTrans.sendVerify)}
                                                </a>
                                                {emailVerifyError &&
                                                    <div className="b-profile-box__row-error">
                                                        {emailVerifyError}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                                {canChange &&
                                    <div className="b-profile-box__row__action">
                                        <a onClick={this.showChangeEmail} className="b-link">{intl.formatMessage(newsletterTrans.change)}</a>
                                    </div>
                                }
                            </div>
                        </div> :
                        <div className={"b-profile-box__add-email"}>
                            <div className={cn("ph-form__field", {
                                "is-filled": this.state.emailInput,
                                "ph-form__field_error": this.state.emailError
                            })}>
                                <div className="ph-form__input-wrapper">
                                    <input className="ph-form__input b-profile-box__input"
                                        ref={el => this.inputEmail = el}
                                        name="email-sub" id="email-sub" type="email"
                                        onChange={this.handleEmailInput}
                                        value={this.state.emailInput} />
                                    <label htmlFor="email-sub" className="ph-form__label">Email</label>
                                </div>
                                {emailError &&
                                    <div className={"ph-form__error"} >
                                        {emailError || this.defaultEmailError}
                                    </div>
                                }
                            </div>

                            <button type="submit" className="ph-form__btn ph-btn b-profile-box__btn is-large"
                                disabled={!this.state.emailInput}
                                onClick={this.onAddEmail}>
                                {intl.formatMessage(newsletterTrans.approveEmail)}
                            </button>
                        </div>
                    }
                </div>

                {hasEmail && this.state.formattedProjects &&
                    <div className="newsletter-subscribed">
                        <h3 className="newsletter-subscribed__header">
                            {intl.formatMessage(newsletterTrans.yourSubscribes)}:
                            {!!subscribedCount && !emailVerified &&
                                <span className="newsletter-subscribed__verify">
                                    <img src={require("../../img/icons/icon_timer.png")} alt=""/>
                                    {intl.formatMessage(newsletterTrans.waitVerify)}
                                </span>
                            }
                        </h3>

                        <div className={cn("ph-form__field ph-select", {
                            "ph-select_opened": openDropdown,
                            "ph-select_has-selected": registeredEdit
                        })}
                        ref={el => this.projectList = el}
                        onClick={this.handleDropdown}
                        data-name="newsletter-project-list"
                        style={{ display: this.state.subscribedCount >= projects.length /*|| !projects*/ ? "none" : "block" }}>
                            <div className="ph-select__default">
                                <span className="ph-select__default-label">
                                    {intl.formatMessage(newsletterTrans.dropdown)}
                                </span>
                                <span className="ph-select__default-select">
                                    {registeredEdit &&
                                        <>
                                            <div className='newsletter-project__logo'>
                                                {registeredEdit.project.favicon_mygames &&
                                                    <img src={`${registeredEdit.project.favicon_mygames}`}
                                                        className="newsletter-project__logo__img" alt=""/>
                                                }
                                            </div>
                                            <span>{registeredEdit.project.name}</span>
                                        </>
                                    }
                                </span>
                                <span className="ph-select__dropdown-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14"
                                        height="46" viewBox="0 0 8 4">
                                        <path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"
                                            d="M7 .683L3.98 4 1 .683 1.4.25l2.58 2.871L6.6.25z"></path>
                                    </svg>
                                </span>
                            </div>
                            <div className="ph-select__dropdown">

                                <WithScroll instance={"registeredList"} maxScrollHeight={250}>
                                    <ul className="ph-select__dropdown-list js-dropdown-list" style={{ maxHeight: "250px" }}>
                                        {projectIdList.map(item => {
                                            const project = formattedProjects[item],
                                                is_subscribed = project.is_subscribed,
                                                { name, ext_id, project_id, favicon_mygames } = project["project"];

                                            if (!is_subscribed) {
                                                return (
                                                    <li key={`reg-list-${project_id}`}
                                                        className="ph-select__dropdown-code"
                                                    > {/*selected*/}
                                                        <a className="b-form__select-dropdown-link js-select-link"
                                                            data-test-game={ext_id}
                                                            onClick={() => this.handleProjectSelect(ext_id)}>
                                                            <div className='newsletter-project__logo'>
                                                                {favicon_mygames &&
                                                                        <img src={`${favicon_mygames}`}
                                                                            className="newsletter-project__logo__img" alt=""/>
                                                                }
                                                            </div>
                                                            {name}
                                                        </a>
                                                    </li>
                                                );
                                            }

                                            return null;
                                        })
                                        }
                                    </ul>
                                </WithScroll>

                            </div>
                        </div>

                        {!this.state.subscribed &&
                            <div className="subscribe-empty">
                                <p>
                                    {intl.formatMessage(newsletterTrans.noSubscribes)}
                                </p>
                            </div>
                        }


                        {registeredEdit && (unsubscribeTypeShort ?
                            (<button type="submit"
                                className="ph-form__btn ph-btn"
                                data-view='registered'
                                data-all={true}
                                onClick={this.subscribe}>
                                {intl.formatMessage(newsletterTrans.subscribeBtn)}
                            </button>)
                            :
                            (<div className="newsletter-type">
                                <div className="newsletter-type__header">
                                    <h4>
                                        {intl.formatMessage(newsletterTrans.newsletterType)} {`${this.state.registeredEdit.project.name}`}</h4>
                                    <div className="newsletter-type__header-control">
                                        <div className="ph-form__checkbox ph-checkbox">
                                            <input className="ph-checkbox__input"
                                                type="checkbox"
                                                checked={this.state.checkAll}
                                                onChange={this.selectAll}
                                                id="newsletter__select-all" />
                                            <label htmlFor="newsletter__select-all" className="ph-checkbox__label">
                                                {intl.formatMessage(newsletterTrans.selectAll)}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ul className='newsletter-type__list'>
                                    {this.state.registeredEdit.project.categories.map(category => {
                                        let { title, description, id } = category,
                                            selectedProjectId = this.state.registeredEdit.project.project_id,
                                            subscribedCaterogries = this.state.registeredEdit.subscribed_categories;

                                        return (
                                            <li key={`selected-${selectedProjectId}-${id}`}
                                                className='newsletter-type__list__item'>
                                                <div className="newsletter-type__list__item__descr">
                                                    <h4>{title}</h4>
                                                    <p>{description}</p>
                                                </div>
                                                <div className="newsletter-type__list__item__control">
                                                    <div className="ph-form__checkbox ph-checkbox">
                                                        <input className="ph-checkbox__input"
                                                            type="checkbox"
                                                            onChange={this.selectCategory}
                                                            checked={subscribedCaterogries.includes(id)}
                                                            data-view='registered'
                                                            data-category={id}
                                                            id={`${selectedProjectId}-${id}`}
                                                        />
                                                        <label htmlFor={`${selectedProjectId}-${id}`}
                                                            className="ph-checkbox__label">
                                                                &nbsp;
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                    }
                                </ul>

                                <div className="newsletter-type__action">
                                    {/*{this.state.socAuth &&
                                        <>
                                            <p>Введите email на который хотите получать рассылку</p>
                                            <div className={"ph-form__field " + (user && user.login ? 'is-filled' : '')}>
                                                <div className="ph-form__input-wrapper">
                                                    <input className="ph-form__input b-profile-box__input"
                                                           name="newsletter-email" id="newsletter-email" type="text"
                                                           tabIndex="1"
                                                           value={user ? user.login : ''}/>
                                                    <label htmlFor="newsletter-email"
                                                           className="ph-form__label">Email</label>
                                                </div>
                                            </div>
                                        </>
                                    }*/}
                                    <button type="submit"
                                        className="ph-form__btn ph-btn"
                                        data-view='registered'
                                        onClick={this.subscribe}>
                                        {intl.formatMessage(newsletterTrans.subscribeBtn)}
                                    </button>
                                </div>
                            </div>)
                        )}

                        {this.state.registeredEdit && !this.state.registeredEdit.project.categories.length &&

                            <div className="newsletter-type__action">
                                {/*{this.state.socAuth &&
                                        <>
                                            <p>Введите email на который хотите получать рассылку</p>
                                            <div className={"ph-form__field " + (user && user.login ? 'is-filled': '') }>
                                                <div className="ph-form__input-wrapper">
                                                    <input className="ph-form__input b-profile-box__input"
                                                           name="newsletter-email" id="newsletter-email" type="text"
                                                           tabIndex="1"
                                                           value={user ? user.login : ''}/>
                                                    <label htmlFor="newsletter-email"
                                                           className="ph-form__label">Email</label>
                                                </div>
                                            </div>
                                        </>
                                    }*/}
                                <button type="submit" className="ph-form__btn ph-btn">
                                    {intl.formatMessage(newsletterTrans.subscribeBtn)}
                                </button>
                            </div>
                        }




                        <ul className="newsletter-subscribed__list">
                            {projectIdList.map(item => {
                                const { formattedProjects } = this.state;

                                if (formattedProjects[item]) {
                                    let project = formattedProjects[item],
                                        { is_subscribed, is_confirmed, is_confirmation_letter_sended } = project,
                                        { project_id, name, ext_id, favicon_mygames, unsubscribe_type, send_policy } = project.project,
                                        { subscribedEdit } = this.state,
                                        subscribedCaterogries = this.state.subscribedEdit && this.state.subscribedEdit.subscribed_categories,
                                        unscribeTypeShort = unsubscribe_type === "A" || unsubscribe_type === "B";

                                    if (is_subscribed) {
                                        return (
                                            <li className="newsletter-subscribed__list__item"
                                                key={project_id} data-test-game={ext_id}>
                                                <div className="newsletter-subscribed__list__item__inner">
                                                    <div className="newsletter-subscribed__list__item__descr">
                                                        <div className='newsletter-project__logo'>
                                                            {favicon_mygames &&
                                                            <img src={`${favicon_mygames}`}
                                                                className="newsletter-project__logo__img" alt=""/>
                                                            }
                                                        </div>
                                                        <h4>{name}</h4>
                                                    </div>
                                                    {send_policy === "C" && !is_confirmed && is_confirmation_letter_sended &&
                                                        <div className="newsletter-subscribed__await" onClick={() => this.onAwaitClick(ext_id)}>
                                                            <span className="newsletter-subscribed__await__icon"></span>
                                                            <p>{intl.formatMessage(newsletterTrans.awaitText)}</p>
                                                        </div>
                                                    }
                                                    <div className="newsletter-subscribed__list__item__control">
                                                        <a className="newsletter-subscribed__link newsletter-subscribed__link_change" onClick={() => this.onChangeClick(ext_id)} >
                                                            {intl.formatMessage(newsletterTrans.settings)}
                                                        </a>
                                                    </div>
                                                </div>
                                                {subscribedEdit && (subscribedEdit.project.ext_id === ext_id) &&
                                                <>
                                                    {!unscribeTypeShort &&
                                                    <ul className='newsletter-type__list'>
                                                        {this.state.subscribedEdit.project.categories.map(category => {
                                                            let { title, description, id } = category,
                                                                changedProjectId = this.state.subscribedEdit.project.project_id;

                                                            return (
                                                                <li key={`changed-${changedProjectId}-${id}`}
                                                                    data-category={id}
                                                                    className='newsletter-type__list__item'>
                                                                    <div
                                                                        className="newsletter-type__list__item__descr">
                                                                        <h4>{title}</h4>
                                                                        <p>{description}</p>
                                                                    </div>
                                                                    <div
                                                                        className="newsletter-type__list__item__control">
                                                                        <div
                                                                            className="ph-form__checkbox ph-checkbox">
                                                                            <input
                                                                                className="ph-checkbox__input"
                                                                                type="checkbox"
                                                                                checked={subscribedCaterogries ? subscribedCaterogries.includes(id) : false}
                                                                                data-view='subscribed'
                                                                                data-category={id}
                                                                                onChange={this.selectCategory}
                                                                                id={`changed-${changedProjectId}-${id}`}
                                                                            />
                                                                            <label
                                                                                htmlFor={`changed-${changedProjectId}-${id}`}
                                                                                className="ph-checkbox__label">
                                                                                &nbsp;
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                        }
                                                    </ul>
                                                    }
                                                    <div className="newsletter-subscribed__control">
                                                        {!unscribeTypeShort ?
                                                            <button type="submit"
                                                                data-view='subscribed'
                                                                onClick={this.subscribe}
                                                                className="ph-form__btn ph-btn">
                                                                {intl.formatMessage(newsletterTrans.saveChanges)}
                                                            </button> :
                                                            <button type="submit"
                                                                data-view='subscribed'
                                                                onClick={() => this.unsubscribe(ext_id)}
                                                                className="ph-form__btn ph-btn">
                                                                {intl.formatMessage(newsletterTrans.unsubscribe)}
                                                            </button>
                                                        }
                                                        <a className="newsletter-subscribed__link" onClick={this.cancelChange}>
                                                            {intl.formatMessage(newsletterTrans.cancel)}
                                                        </a>
                                                    </div>
                                                </>
                                                }

                                            </li>
                                        );
                                    }
                                }

                                return null;
                            })
                            }

                        </ul>
                    </div>
                }
                {this.state.showConfirm &&
                <Popup onClose={this.closePopup} isOpen={this.state.showConfirm}>
                    <div className="newsletter-popup__content">
                        <div className="newsletter-popup__icon newsletter-popup__icon_verify" />
                        <h2 className="newsletter-popup__header">
                            {intl.formatMessage(newsletterTrans.verifyHeader)}
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage(newsletterTrans.verifyText, { mail: this.state.emailForVerification || user.profile_fields.alt_email }) }}></p>
                        <div className="newsletter-popup__action">
                            <button type="submit"
                                onClick={this.closePopup}
                                className="ph-form__btn ph-btn newsletter-popup__confirm">
                                {intl.formatMessage(newsletterTrans.confirm)}
                            </button>

                        </div>
                    </div>
                </Popup>
                }
                {this.state.showDoubleOptInOk &&
                    <Popup onClose={this.closePopup} isOpen={this.state.showDoubleOptInOk}>
                        <div className="newsletter-popup__content">

                            <div className="newsletter-popup__icon newsletter-popup__icon_ok" />
                            <h2 className="newsletter-popup__header">
                                {intl.formatMessage(newsletterTrans.doubleOptInHeader)}
                            </h2>
                            <p className="newsletter-popup__content__text" dangerouslySetInnerHTML={{ __html: intl.formatMessage(newsletterTrans.doubleOptInText, { game: this.state.doubleOptIn, email: user.profile_fields.alt_email }) }}></p>
                            <p>{intl.formatMessage(newsletterTrans.doubleOptInUnsubscribe)}</p>
                            <div className="newsletter-popup__action">
                                <button type="submit"
                                    onClick={this.closePopup}
                                    className="ph-form__btn ph-btn newsletter-popup__confirm">
                                    {intl.formatMessage(newsletterTrans.confirm)}
                                </button>

                            </div>
                        </div>
                    </Popup>
                }
                {this.state.showSubscribeOk &&
                    <Popup onClose={this.closePopup} isOpen={this.state.showSubscribeOk}>
                        <div className="newsletter-popup__content">

                            <div className="newsletter-popup__icon newsletter-popup__icon_ok" />
                            <h2 className="newsletter-popup__header">
                                {intl.formatMessage(newsletterTrans.subscribeSuccess)}
                            </h2>
                            <div className="newsletter-popup__action">
                                <button type="submit"
                                    onClick={this.closePopup}
                                    className="ph-form__btn ph-btn newsletter-popup__confirm">
                                    {intl.formatMessage(newsletterTrans.confirm)}
                                </button>

                            </div>
                        </div>
                    </Popup>
                }
                {this.state.showUnsubscribe &&
                <Popup onClose={this.closePopup} isOpen={this.state.showUnsubscribe} >
                    <div className="newsletter-popup__content">

                        <div className="newsletter-popup__icon newsletter-popup__icon_ok" />
                        <h2 className="newsletter-popup__header">
                            {intl.formatMessage(newsletterTrans.unsubscribeHeader)}
                        </h2>
                        <p className="newsletter-popup__text">
                            {intl.formatMessage(newsletterTrans.unsubscribeText)}
                            <span>{`${ this.state.subscribedEdit.project.name}`}</span>
                        </p>
                        <div className="newsletter-popup__action">
                            <button type="submit"
                                onClick={(e) => this.unsubscribe(this.state.unsubscribeId, { withConfirm: false })}
                                className="ph-form__btn ph-btn newsletter-popup__confirm">
                                {intl.formatMessage(newsletterTrans.unsubscribe)}
                            </button>
                            <a className="newsletter-subscribed__link"  onClick={this.closePopup} >
                                {intl.formatMessage(newsletterTrans.cancel)}
                            </a>

                        </div>
                    </div>
                </Popup>
                }
                {this.state.showAwaitPopup &&
                    <Popup onClose={this.closePopup} isOpen={this.state.showAwaitPopup}>
                        <div className="newsletter-popup__content">

                            <div className="newsletter-popup__icon newsletter-popup__icon_await" />
                            <h2 className="newsletter-popup__header">
                                {intl.formatMessage(newsletterTrans.awaitPopupHeader)}
                            </h2>
                            <p className="newsletter-popup__text" dangerouslySetInnerHTML={{ __html: intl.formatMessage(newsletterTrans.awaitPopupContent, { mail: user.profile_fields.alt_email }) }}></p>
                            <div className="newsletter-popup__action">
                                <button type="submit"
                                    onClick={this.closePopup}
                                    className="ph-form__btn ph-btn newsletter-popup__confirm">
                                    {intl.formatMessage(newsletterTrans.confirm)}
                                </button>
                                <a className="newsletter-subscribed__link"  onClick={() => this.sendAwait(this.state.awaitEdit)}>
                                    {intl.formatMessage(newsletterTrans.resend)}
                                </a>
                            </div>
                        </div>
                    </Popup>
                }
                {unsubscribeMultiPopup &&
                    <Popup onClose={this.closePopup}
                        title={intl.formatMessage(newsletterTrans.munsTitle)}
                        isOpen={this.state.unsubscribeMultiPopup}
                        headerAlign={"left"}
                        unsubscribeMulti={true}>
                        <div className="content content_top">
                            <p className="text" dangerouslySetInnerHTML={{ __html: intl.formatMessage(newsletterTrans.munsDone, {
                                project: formattedProjects ? formattedProjects[unsubscribeMultiPopup.project].project.name : ""
                            }) }}>

                            </p>

                            <p className="text text_small">{intl.formatMessage(newsletterTrans.munsSeveral)}</p>
                        </div>

                        <div className="b-popup__inner b-popup__inner_wide">
                            <div className="account-list">
                                {unsubscribeMultiPopup.profiles.map((account, index) => {
                                    return  (
                                        <a href={account.url} key={index} className="account-list__item" target={"_blank"}>
                                            <img src={account.avatar} alt=""/>
                                            {account.nick}
                                        </a>
                                    );})
                                }
                            </div>
                        </div>

                        <div className="content content_bottom">

                            <p className="b-popup__tip">
                                <img src={require("../../img/svg/icon-info.svg")} alt=""/>
                                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(newsletterTrans.munsCondition, {
                                    project: formattedProjects ? formattedProjects[unsubscribeMultiPopup.project].project.name : ""
                                }) }} />
                            </p>

                            <div className="b-popup__action b-popup__action_noPadding">
                                <button type="submit"
                                    onClick={this.closePopup}
                                    className="ph-form__btn ph-btn">
                                    {intl.formatMessage(newsletterTrans.close)}
                                </button>
                            </div>
                        </div>
                    </Popup>
                }

                {this.state.showChangeEmailPopup &&
                    <ChangeEmailPopup onClose={this.closePopup}
                        isOpen = {this.state.showChangeEmailPopup}
                        emailError={this.state.emailError}
                        onSend={this.onAddEmail}
                        actualEmail={this.state.emailForVerification || user.profile_fields.alt_email || ""}
                    />
                }
            </>
        );
    }
}

export default injectIntl(NewsletterContent);
