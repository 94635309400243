import React, { useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import cn from "classnames";

import Popup from "./Popup.jsx";

const messages = defineMessages({
    text: {
        id: "newsletter.changeEmail.text",
        defaultMessage: "Укажите актуальный адрес электронной почты, чтобы получать спецпредложения и свежие новости по проектам."
    },
    header: {
        id: "newsletter.changeEmail.header",
        defaultMessage: "Смена email"
    },
    approveEmail: {
        id: "newsletter.email.approve",
        defaultMessage: "Подтвердить email"
    },
    emailDefaultError: {
        id: "newsletter.email.defaultError",
        defaultMessage: "Неверный email"
    },
});

const ChangeEmailPopup = ({ actualEmail, onSend, onClose, isOpen, emailError }) => {
    const [emailVal, setEmailVal] = useState("");
    const [emailValidity, setEmailValidity] = useState(true);
    const { formatMessage } = useIntl();

    const onInput = (e) => {
        setEmailVal(e.target.value);
        setEmailValidity(true);
    };

    const validateEmail = (email) => {
        let valid = false;

        if (email.indexOf("@") > -1 && email.length > 4) {
            valid = true;
        }

        return valid;
    };

    const verifyBeforeSend = () => {
        const prepared = emailVal.trim();
        const isValid = validateEmail(prepared);
        const notSame = actualEmail !== prepared;

        if (isValid && notSame) {
            onSend({ email: prepared });
        }
        else {
            setEmailValidity(false);
        }
    };

    return (
        <Popup
            onClose={onClose}
            isOpen={isOpen}
            changeEmail
            title={formatMessage(messages.header)}
            headerAlign={"left"}>
            <form className="b-popup__inner" onSubmit={e => e.preventDefault()}>
                <p className="b-popup__text b-popup__text_no-marginTop"
                    dangerouslySetInnerHTML={{ __html: formatMessage(messages.text) }}/>

                <div className={cn("ph-form__field", {
                    "is-filled": emailVal.length,
                    "ph-form__field_error": !emailValidity || emailError
                })}>
                    <div className="ph-form__input-wrapper">
                        <input
                            className="ph-form__input b-profile-box__input"
                            name="email-change"
                            id="email-change"
                            type="text"
                            onChange={onInput}
                            value={emailVal}
                        />
                        <label htmlFor="email-change" className="ph-form__label">Email</label>
                    </div>
                    {(!emailValidity || emailError) &&
                    <div className={"ph-form__error"} >
                        {emailError || formatMessage(messages.emailDefaultError)}
                    </div>
                    }
                </div>

                <div className="b-popup__action b-popup__action_noPadding">
                    <button type="submit"
                        onClick={verifyBeforeSend}
                        className="ph-form__btn ph-btn">
                        {formatMessage(messages.approveEmail)}
                    </button>
                </div>
            </form>
        </Popup>
    );
};

export default ChangeEmailPopup;
