import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { Backend, getGemHost } from "@vkplay/shared";

import withLang from "../withLang.jsx";
import ShowAll from "../ShowAll.jsx";
import LawDocument from "./LawDocument.jsx";

import messages from "../../services/Messages";

import "../../../css/_laws.css";

const WRAP_DOC_COUNT = 5;

const Laws = ({ user, lang }) => {
    const intl = useIntl();
    const [wrapped, setWrapped] = useState(true);
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const showAllVisible = useMemo(() => docs.length > WRAP_DOC_COUNT, [docs.length]);
    const rendered = useMemo(() => wrapped ? docs.slice(0, WRAP_DOC_COUNT) : docs, [wrapped, docs]);

    const fetchLaws = useCallback(() => {
        setLoading(true);
        Backend.get(`https://${getGemHost("api")}/account/terms/list_accepted/`)
            .then(result => {
                setDocs(result.terms);
            })
            .catch(console.error)
            .then(() => setLoading(false));
    }, []);

    const subscribeToGemTermsAccept = useCallback(() => {
        window.__GEMS.onUserTermsAccept = () => fetchLaws();
    }, [fetchLaws]);

    useEffect(() => {
        if (user) {
            subscribeToGemTermsAccept();
            fetchLaws();
        }
    }, [user, fetchLaws, subscribeToGemTermsAccept]);

    return (
        <div className={`b-profile-laws-box ${loading ? "b-profile-laws-box__loading" : ""}`}>
            <h2 className="b-profile-box__heading">
                {intl.formatMessage(messages.lawsTitle)}
            </h2>
            <div className="b-profile-box__content laws__content">
                <p className="b-profile-box__p">
                    {intl.formatMessage(messages.lawsDescription)}
                </p>
                <div className="b-profile-box__action laws__docs">
                    {rendered.map(doc => <LawDocument key={Math.random()} {...doc} lang={lang}/>)}
                </div>
                {showAllVisible && (
                    <ShowAll
                        wrapped={wrapped}
                        className="laws__show__all"
                        onClick={() => setWrapped(!wrapped)}
                    />
                )}
            </div>
        </div>
    );
};

export default withLang(Laws);
