import React from "react";
import { useIntl } from "react-intl";
import Timeago from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

// Formatters

import ru from "react-timeago/lib/language-strings/ru";
import en from "react-timeago/lib/language-strings/en";
import de from "react-timeago/lib/language-strings/de";
import fr from "react-timeago/lib/language-strings/fr";
import es from "react-timeago/lib/language-strings/es";
import pl from "react-timeago/lib/language-strings/pl";
import tr from "react-timeago/lib/language-strings/tr";

const formatter = {
    ru: buildFormatter(ru),
    en: buildFormatter(en),
    de: buildFormatter(de),
    fr: buildFormatter(fr),
    es: buildFormatter(es),
    pl: buildFormatter(pl),
    tr: buildFormatter(tr),
};

export default (props) => {
    const { lang } = props;
    const intl = useIntl();
    const locale = lang.split("-")[0] || "en";

    return <Timeago {...props} formatter={formatter[locale]} />;
};
