import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import loadImage from "blueimp-load-image";
import cn from "classnames";
import { Backend, getGemHost, isMyGames, isVkPlay } from "@vkplay/shared";
import SVGInline from "react-svg-inline";

import Popup from "./Popup.jsx";
import WithScroll from "./withScroll.jsx";
import { showNotify } from "../useProfileSettings";

import IconDownload from "!raw-loader!../../img/svg/icon-download.svg";
import IconGallery from "!raw-loader!../../img/svg/icon-gallery.svg";

import "react-image-crop/dist/ReactCrop.css";

const messages = defineMessages({
    download: {
        id: "avatar.download",
        defaultMessage: "Загрузить"
    },
    gallery: {
        id: "avatar.gallery",
        defaultMessage: "Галерея"
    },
    chooseImage: {
        id: "avatar.chooseImage",
        defaultMessage: "Выберите изображение"
    },
    save: {
        id: "avatar.save",
        defaultMessage: "Сохранить"
    },
    c2a: {
        id: "avatar.c2a",
        defaultMessage: "Перетащите сюда или"
    },
    cancel: {
        id: "avatar.cancel",
        defaultMessage: "Отмена"
    },
    minSize: {
        id: "avatar.size",
        defaultMessage: "Минимальный размер изображения 180х180"
    },
    minSizePx: {
        id: "avatar.size.px",
        defaultMessage: "Изображение должно быть не меньше 180х180 px"
    },
    avatarUpd: {
        id: "avatar.updated",
        defaultMessage: "Аватар обновлён"
    },
    settings: {
        id: "avatar.settings",
        defaultMessage: "Настройка аватара"
    },
    maxSize: {
        id: "avatar.maxSize",
        defaultMessage: "Максимальный размер: 5 MB"
    },
    tip: {
        id: "avatar.tip",
        defaultMessage: "Укажите нужную область изображения, смещая рамку."
    }
});

const avatarUrlOrigin = location.origin.replace("account", "avatar");

class AvatarSettingsContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAvatar: null,
            activeTab: this.props.activeTab,
            drag: false,
            files: null,
            previewUrl: null,
            errorText: null,
            crop: {
                unit: "px",
                aspect: 1,
                width: 180,
                height: 180,
            },
            croppedValidity: false
        };

        this.urls = {
            upload: `https://${getGemHost("api")}/account/userinfo/userpic_upload/`,
        };

        this.imageRef = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeTab != this.props.activeTab) {
            this.setState({
                activeTab: this.props.activeTab
            });
        }
    }

    renderAvatarList = () => {
        let list = [];

        if (isMyGames) {
            // Hawked, WRF
            for (let i = 117; i <= 145; i++) {
                list.push(
                    <div key={i} className={cn("b-avatar__list__item", {
                        "is-selected": this.state.selectedAvatar === i
                    })} onClick={() => this.selectAvatar(i)}>
                        <div className="b-avatar__list__item-wrapper">
                            <img src={`${avatarUrlOrigin}/stubs/${i}.jpeg`} alt={i} />
                        </div>
                    </div>
                );
            }
        }

        if (isVkPlay) {
            // AH
            for (let i = 109; i <= 116; i++) {
                list.push(
                    <div key={i} className={cn("b-avatar__list__item", {
                        "is-selected": this.state.selectedAvatar === i
                    })} onClick={() => this.selectAvatar(i)}>
                        <div className="b-avatar__list__item-wrapper">
                            <img src={`${avatarUrlOrigin}/stubs/${i}.jpeg`} alt={i} />
                        </div>
                    </div>
                );
            }
        }

        for (let i = 1; i <= 104; i++) {
            if (i !== 73 && i !== 74) {
                list.push(
                    <div key={i} className={cn("b-avatar__list__item", {
                        "is-selected": this.state.selectedAvatar === i
                    })} onClick={() => this.selectAvatar(i)}>
                        <div className="b-avatar__list__item-wrapper">
                            <img src={`${avatarUrlOrigin}/stubs/${i}.jpeg`} alt={i} />
                        </div>
                    </div>
                );
            }
        }

        return list;
    };

    selectAvatar = (num) => {
        let { selectedAvatar } = this.state;

        if (num === selectedAvatar) {
            this.setState({
                selectedAvatar: null
            });

            return false;
        };

        this.setState({
            selectedAvatar: num
        });
    }

    toggleTab = (tab) => {
        this.setState({
            activeTab: tab,
            selectedAvatar: null,
            previewUrl: null,
            croppedValidity: false
        });
    }

    makeClientAvatar = (imgNum) => {
        let avatar = document.querySelector(".b-profile-box__avatar-img"),
            headerAvatar = document.querySelector(".ph-user__avatar img"),
            link = `${avatarUrlOrigin}/stubs/${imgNum}.jpeg`;

        if (headerAvatar) {
            headerAvatar.src = link;
        }
        avatar.src = link;
    }


    getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
                value: function (callback, type, quality) {
                    var dataURL = this.toDataURL(type, quality).split(",")[1];
                    setTimeout(function() {

                        var binStr = atob(dataURL),
                            len = binStr.length,
                            arr = new Uint8Array(len);

                        for (var i = 0; i < len; i++) {
                            arr[i] = binStr.charCodeAt(i);
                        }

                        callback(new Blob([arr], { type: type || "image/png" }));

                    });
                }
            });
        }


        canvas.toBlob(blob => {
            blob.name = fileName;
            const source = window.URL.createObjectURL(blob);
            let avatar = document.querySelector(".b-profile-box__avatar-img"),
                headerAvatar = document.querySelector(".ph-user__avatar img");

            this.setState({
                clientAvatar: source  // setstate to fix bug with cropp
            });

            if (headerAvatar) {
                headerAvatar.src = source;
            }
            avatar.src = source;
        }, "image/jpeg");
    }

    save = () => {
        let opts = {},
            { selectedAvatar, activeTab } = this.state;

        this.setState({
            loading: true
        });

        if (activeTab === "gallery" && selectedAvatar) {
            opts.userpic = selectedAvatar;
            Backend.post(this.urls.upload, opts).then(result => {
                if (result.status === "ok") {
                    this.props.onClose();
                    this.makeClientAvatar(selectedAvatar);
                    this.setState({
                        loading: false,
                        selectedAvatar: null,
                        previewUrl: null,
                        croppedValidity: false
                    });
                    showNotify({ type: "avatar", text: this.props.intl.formatMessage(messages.avatarUpd) });

                    window.gc_refresh_account && window.gc_refresh_account();
                }
            });
        }
        else {
            let { crop, files } = this.state,
                scaleX = this.imageRef.naturalWidth / this.imageRef.width,
                scaleY = this.imageRef.naturalHeight / this.imageRef.height;

            opts = {
                x: parseInt(crop.x * scaleX),
                y: parseInt(crop.y * scaleY),
                width: parseInt(crop.width * scaleX),
                height: parseInt(crop.height * scaleY),
            };

            Backend.post_formdata(this.urls.upload, opts, { file: files[0], fileField: "userpic" }).then(result => {
                if (result.status === "ok") {

                    this.getCroppedImg(this.imageRef, crop, "avatar");
                    this.setState({
                        loading: false,
                        selectedAvatar: null,
                        previewUrl: null,
                        croppedValidity: false

                    });
                    this.props.onClose();
                    showNotify({ type: "avatar", text: this.props.intl.formatMessage(messages.avatarUpd) });
                    window.gc_refresh_account && window.gc_refresh_account();
                }
                else {
                    this.setState({
                        loading: false,
                        errorText: result.body.error
                    });
                }
            });
        }
    };

    getPreview = () => {
        // let reader = new FileReader();
        //
        // reader.readAsDataURL(this.state.files[0]);
        // reader.onloadend = () => {
        this.setState({
                // previewUrl: reader.result,
            previewUrl: this.state.files[0].preview,  // EXIF FIX , commented old version
            croppedValidity: true
        });
        // };
    }

    cancelDrag = () => {
        this.setState({
            drag: false
        });
    }

    onDragEnter = () => {
        this.setState({
            drag: true
        });
    }

    onDrop = (files) => {
        if (!files.length) {
            this.setState({
                drag: false
            });

            return false;
        }

        if (files[0].size / 1024 / 1024 > 5) {
            this.setState({
                errorText: this.props.intl.formatMessage(messages.maxSize)
            });

            return false;
        }

        loadImage(files[0], (canvas) => {
            files[0].preview = canvas.toDataURL(files[0].type);  //rewrite preview
            this.setState({
                files: files,
                errorText: null
            }, this.getPreview);
        }, { orientation: true });

        // EXIF FIX , commented old version
        // this.setState(
        //     {
        //         files,
        //         drag: false
        //     },
        //     () => {
        //         this.getPreview();
        //     }
        // );
    }

    onImageLoaded = image => {
        if (image.naturalWidth < 180 || image.naturalHeight < 180) {
            this.setState({
                errorText: this.props.intl.formatMessage(messages.minSize),
                previewUrl: null,
                files: null
            });

            return false;

        }
        this.imageRef = image;

        this.setState({
            crop: {
                unit: "px",
                aspect: 1,
                width: 180,
                height: 180,
                x: (image.width / 2) - 90,
                y: (image.height / 2) - 90,
            }
        });

        return false;
    };

    onCancel = () => {
        if (this.state.previewUrl) {
            this.setState({
                previewUrl: null,
                errorText: ""
            });

            return false;
        }
        this.props.onClose();
    }

    onCrop = (crop, percentCrop) => {
        const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
        const scaleY = this.imageRef.naturalHeight / this.imageRef.height;

        if (crop.width * scaleX < 180 || crop.height * scaleY < 180) {
            this.setState({
                crop,
                croppedValidity: false,
                errorText: this.props.intl.formatMessage(messages.minSizePx)
            });

            return false;
        }

        crop.aspect = 1;
        this.setState({ crop, croppedValidity: true, errorText: "" });
    };


    render() {
        let { isOpen, intl, onClose } = this.props,
            { loading, selectedAvatar, activeTab, previewUrl, croppedValidity } = this.state,
            saveDisabled = loading ||
                (activeTab === "gallery" && !selectedAvatar) ||
                (activeTab === "upload" && !croppedValidity);

        return (
            <Popup
                isOpen={isOpen}
                size={"huge"}
                avatarSettings={true}
                title={intl.formatMessage(messages.settings)}
                onClose={onClose}
            >

                <div className="b-avatar__content">
                    {this.state.clientAvatar &&
                       <img src={this.state.clientAvatar} className="b-avatar__cropped-avatar" alt=""/>
                    }
                    <div className="b-avatar__control">
                        <div className={cn("b-avatar__control__item", {
                            "is-active": this.state.activeTab === "upload"
                        })} onClick={() => this.toggleTab("upload")}>
                            <SVGInline svg={IconDownload} className={"b-avatar__control__icon"}/>
                            <span>{intl.formatMessage(messages.download)}</span>
                        </div>
                        <div className={cn("b-avatar__control__item", {
                            "is-active": this.state.activeTab === "gallery"
                        })} onClick={() => this.toggleTab("gallery")}>
                            <SVGInline svg={IconGallery} className={"b-avatar__control__icon"}/>
                            <span>{intl.formatMessage(messages.gallery)}</span>
                        </div>
                    </div>
                    <div className="b-avatar__container">
                        {this.state.previewUrl &&
                            <div className="b-avatar__preview">
                                <ReactCrop src={this.state.previewUrl}
                                    circularCrop={1}
                                    crop={this.state.crop}
                                    onChange={this.onCrop}
                                    onImageLoaded={this.onImageLoaded}
                                    minWidth={50}
                                    minHeigt={50} />
                            </div>
                        }

                        {activeTab === "upload" && !previewUrl &&
                            <div className={cn("b-avatar__upload", {
                                "has-upload": this.state.uploadedAvatar
                            })}>
                                <img src={require("../../img/icons/icon_avatar.png")} className="b-avatar__upload-icon" alt=""/>
                                <p className="b-avatar__c2a">{intl.formatMessage(messages.c2a)}</p>
                                <label htmlFor="input-file" className="b-avatar__drop-btn">
                                    {intl.formatMessage(messages.chooseImage)}
                                </label>


                                <Dropzone
                                    accept="image/jpeg, image/png"
                                    onDrop={this.onDrop}
                                    onDragEnter={this.onDragEnter}
                                    onDragLeave={this.cancelDrag}
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps()}
                                            className={"dropzone " + (this.state.drag ? "is-active" : "")}
                                            id={"dropZone"}>
                                            <input
                                                {...getInputProps()}
                                                id="input-file"
                                                type="file"
                                                className={"b-avatar_input-file"}
                                            />
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        }
                        {activeTab === "gallery" && !previewUrl &&
                            <div className="b-avatar__list-wrapper">
                                <WithScroll instance='avatarList' maxScrollHeight={350}>
                                    <div className={cn("b-avatar__list", {
                                        "has-selected": this.state.selectedAvatar
                                    })} style={{ maxHeight: "350px" }}>
                                        {this.renderAvatarList()}
                                    </div>
                                </WithScroll>
                            </div>
                        }


                        {activeTab === "upload" && previewUrl &&
                            <div className={cn("b-avatar__tip")}>
                                {intl.formatMessage(messages.tip)}
                            </div>
                        }

                        {this.state.errorText &&
                        <p className="b-avatar__error-text">
                            {this.state.errorText}
                        </p>
                        }

                        <div className="b-avatar__action">
                            {(activeTab === "upload" && previewUrl) &&
                                <button type="submit" className="ph-form__btn ph-form__btn_save ph-btn"
                                    onClick={this.save}
                                    disabled={saveDisabled} >{intl.formatMessage(messages.save)}</button>

                            }
                            {activeTab === "gallery" &&
                                <button type="submit" className="ph-form__btn ph-form__btn_save ph-btn"
                                    onClick={this.save}
                                    disabled={saveDisabled} >{intl.formatMessage(messages.save)}</button>
                            }
                            <button type="button" className="b-profile__btn ph-form__btn ph-form__btn_cancel"
                                data-target="choose"
                                onClick={this.onCancel}>{intl.formatMessage(messages.cancel)}</button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}


export default injectIntl(AvatarSettingsContent);
