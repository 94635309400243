import React, { Component } from "react";
import ajax from "superagent";
import { getCookie, build_api_url } from "@vkplay/shared";
import { injectIntl, defineMessages } from "react-intl";

const activeChannel = defineMessages({
    title: {
        id: "translation.channel.title",
        defaultMessage: "Платформа"
    },
    change: {
        id: "translation.channel.change",
        defaultMessage: "Сменить"
    },
    ready: {
        id: "translation.channel.ready",
        defaultMessage: "Всё готово к началу трансляции"
    },
    custom: {
        id: "translation.custom",
        defaultMessage: "Ручная настройка"
    },
    account: {
        id: "translation.custom.account",
        defaultMessage: "Аккаунт"
    },
    start: {
        id: "translation.custom.start",
        defaultMessage: "Запустить"
    }
});

class ChannelActive extends Component {
    constructor (props) {
        super(props);

        this.state = {
            noStart: false
        };
    }

    componentDidMount () {
        this.checkOptions();
    }

    checkOptions = () => {
        const search = new URLSearchParams(location.search);

        if (search.get("uimode") === "nostart") {
            this.setState({
                noStart: true
            });
        }
    }

    deleteTranslationData = () => {
        const { channelActive } = this.props;

        ajax.delete(build_api_url(`/account/streams/channel/${channelActive.channel_type}/`), {
            method: "DELETE"
        }).set("X-JWTCSRFToken", window.__GEMS.token)
            .withCredentials()
            .then(response => {
            	if (response.ok) {
                    this.props.deleteChannel();
                    setTimeout(() => {
                        window.gc_set_stream_auth_invalidated && window.gc_set_stream_auth_invalidated();
                    }, 1000);
                }
            })
            .catch(error => console.log(error));
    }

    streamingStart = () => {
        window.gc_start_stream();
        window.gc_close_window();
    }

    channelList = {
        "T": "twitch",
        "C": "custom",
        "M": "mixer",
        "Y": "youtube",
        "L": "vkplay_live"
    }

    render () {
        const { noStart } = this.state;
        const { gamecenter, channelActive, intl } = this.props;
        const channelName = (this.channelList)[channelActive.channel_type];
        const custom = channelActive.channel_type === "C";

        return (
            <div className="b-translation">
                <div className="b-translation__caption">
                    <div className="b-translation__subtitle">{intl.formatMessage(activeChannel.title)}</div>
                    <div className="b-translation__settings b-link" onClick={this.deleteTranslationData}>
                        {intl.formatMessage(activeChannel.change)}
                    </div>
                </div>
                <div className="b-active-channel">
                    <div className={`b-active-channel__name b-active-channel__${channelName}`}>
                        {custom ? `${intl.formatMessage(activeChannel.custom)}` : channelActive.channel_name}
                    </div>
                    <div className="b-active-channel__account">
                        {custom ? channelActive.url : `${intl.formatMessage(activeChannel.account)}: ${channelActive.name}`}
                    </div>
                    {custom &&
                    <i className="b-active-channel__gear-icon" onClick={() => this.props.openManualSettings()}/>}
                </div>
                <div className="b-active-channel__set">
                    {intl.formatMessage(activeChannel.ready)}
                </div>
                {gamecenter && !noStart && (
                    <div className="b-active-channel__start b-custom-settings__button" onClick={this.streamingStart}>
                        {intl.formatMessage(activeChannel.start)}
                    </div>
                )}
            </div>
        );
    }
}

export default injectIntl(ChannelActive);
