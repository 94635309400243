import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import SecurityContent from "./SecurityContent.jsx";
import withLang from "../withLang.jsx";

const Security = ({ messages, lang, user }) => {
    return (
        <IntlProvider locale={lang} messages={messages}>
            <SecurityContent user={user}/>
        </IntlProvider>
    );
};

export default withLang(Security);
