import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import messages from "../services/Messages";

import "../../css/_show-all.css";

const ShowAll = ({ wrapped = true, className = "", ...restProps }) => {
    const intl = useIntl();
    const text = useMemo(() => intl.formatMessage(wrapped ? messages.showAll : messages.hide), [wrapped]);
    const toggledClassName = useMemo(() => wrapped ? "" : "b-profile-box__show__all__toggled", [wrapped]);

    return (
        <div {...restProps} className={`b-profile-box__show__all ${toggledClassName} ${className}`}>
            {text}
        </div>
    );
};

export default ShowAll;