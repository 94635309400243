import React from "react";
import { useIntl } from "react-intl";
import { isVkPlay } from "@vkplay/shared";
import AltLoginItem from "./AltLoginItem.jsx";
import messages from "../SecurityMessages";

const AltLoginBlock = (props) => {
    const { origin, allowed, existing, onUpdateList } = props;
    const intl = useIntl();

    return (
        <div className="b-profile-box__logins">
            <div className="b-profile-box__security-field__header">{intl.formatMessage(messages.altLoginTitle)}</div>
            <p>
                {intl.formatMessage(messages.altLoginSubtitle, { platform: isVkPlay ? "VK Play" : "MY.GAMES" })}
            </p>
            {allowed.map((origin, idx) => {
                return (
                    <AltLoginItem
                        key={idx}
                        origin={origin}
                        onUpdateList={onUpdateList}
                        allowed={allowed}
                    />
                );
            })}
            {existing.map((item, idx) => {
                if (item.origin === origin) {
                    return null;
                }

                return (
                    <AltLoginItem
                        key={idx}
                        origin={item.origin}
                        login={item.login}
                        onUpdateList={onUpdateList}
                    />
                );
            })}
        </div>
    );
};

export default AltLoginBlock;
