import React from "react";
import baron from "baron";
import cn from "classnames";

class WithScroll extends React.Component {
    state = {
        id: "",
        showTrack: false
    }

    componentDidMount = () => {
        const id = `scroll_${String(Math.random()).substring(2, 5)}`;

        this.setState(() => ({ id }), this.initBaronScroller);

        let { instance } = this.props;

        this.recalculateGcScroll = instance === "registeredList" && setInterval(this.fixScrollinGC, 500);
        // bug in baron? on any change style of baron scroller or tracker, it make ShowTrack: true, fixed by this
    }

    componentWillUnmount () {
        this.recalculateGcScroll && clearInterval(this.recalculateGcScroll);
    }

    fixScrollinGC = () => {
        if (this.scroller) {
            let h = Number(getComputedStyle(this.scroller).height.replace("px", ""));

            if (h) {
                this.setState({
                    showTrack: h >= this.props.maxScrollHeight
                }, () => {
                    this.scrollerInstance.update();
                });
            }
        }
    }

    initBaronScroller = () => {
        const { instance } = this.props;
        const { id } = this.state;

        this.scrollerInstance = baron({
            root: `#${id}`,
            scroller: `.js-gc-scrollable__scroller-${instance}`,
            track: `.js-gc-scrollable__track-${instance}`,
            bar: `.js-gc-scrollable__bar-${instance}`,
            barOnCls: "gc-baron-active",
        });
    }

    render = () => {
        const { children, className, instance, maxScrollHeight } = this.props;
        const { id } = this.state;

        return (
            <div id={id} className={cn("b-gc-scrollable", className)} style={{ maxHeight: `${maxScrollHeight}px` || "auto" }}>
                <div ref={el => this.scroller = el} className={cn("b-gc-scrollable__scroller", `js-gc-scrollable__scroller-${instance}`)}>
                    {children}
                </div>
                <div className="b-gc-scrollable__track-container" style={{ display: this.state.showTrack ? "block" : "none" }}> {/* baron bug */}
                    <div className={cn("b-gc-scrollable__track", `js-gc-scrollable__track-${instance}`)}>
                        <div className={cn("b-gc-scrollable__bar", `js-gc-scrollable__bar-${instance}`)}></div>
                    </div>
                </div>
            </div>
        );
    }
};


export default WithScroll;
