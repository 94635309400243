import React from "react";
import ReactDOM from "react-dom";

import Translation from "./components/Translation/Translation.jsx";

window.gc_stream_auth_code = (code) => {
    const trans = window.ProfileTranslation;
    const check = trans && trans.onGcAuthCode;

    check && trans.onGcAuthCode(code);
};

const ProfileTranslation = {
    init: function () {
        const inGameCenterPage = window.location.pathname.indexOf("/gamecenter/") > -1 || window.location.search.indexOf("in_gamecenter=1") > -1;
        let translationContainer = document.getElementById("translation-channels");

        translationContainer && ReactDOM.render(<Translation gamecenter={inGameCenterPage} />, translationContainer);
    }
};

export default ProfileTranslation;
