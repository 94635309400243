import React from "react";
import ReactDOM from "react-dom";

import Laws from "./components/laws/Laws.jsx";

import * as selectors from "./services/Selectors";

const LawsApp = {
    init: () => {
        const container = document.getElementById(selectors.LAWS);

        container && ReactDOM.render(<Laws />, container);
    }
};

export default LawsApp;
