import { inGamecenter } from "@vkplay/shared";

export function findAncestor ($elem, selector) {
    if (!$elem) {
        return;
    }

    let $el = $elem;

    while (($el = $el.parentElement) && !($el.matches ? $el.matches(selector) : $el.msMatchesSelector(selector)));

    return $el;
}

export function scrollTo ($elem, offset) {
    if (!$elem) {
        return;
    }

    offset = offset || 0;

    let scrollTop = (() => {
        if (inGamecenter) {
            let scrollEl = document.querySelector(".js-gc-scrollable__scroller");

            return scrollEl.scrollTop;
        } else {
            return window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        }
    })();

    let elemPosition = $elem.getBoundingClientRect().top + scrollTop,
        offsetPosition = elemPosition - offset,
        checkScrollBehavior = "scrollBehavior" in document.documentElement.style;

    if (!checkScrollBehavior) {
        window.scrollTo(0, offsetPosition);

        return false;
    }

    if (inGamecenter) {
        let scrollEl = document.querySelector(".js-gc-scrollable__scroller");

        if (!checkScrollBehavior) {
            scrollEl.scrollTo(0, offsetPosition);

            return false;
        }

        scrollEl.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });

        return false;
    }

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

export function getImageSize (src, callback) {
    const $img = new Image();

    $img.onload = () => {
        callback && callback({
            width: $img.width,
            height: $img.height
        });
    };

    $img.src = src;
}

export let isMob = () => {
    return window.matchMedia("(max-width: 1000px)").matches;
};

export let serialize = function (form) {
    let serialized = {};

    for (let i = 0; i < form.elements.length; i++) {

        let field = form.elements[i];

        if (!field.name || field.disabled || field.type === "file" || field.type === "reset" || field.type === "submit" || field.type === "button") continue;
        if (field.type === "select-multiple") {
            for (let n = 0; n < field.options.length; n++) {
                if (!field.options[n].selected) continue;
                serialized[field.name] = field.options[n].value;
            }
        }
        else if ((field.type !== "checkbox" && field.type !== "radio") || field.checked) {
            serialized[field.name] = field.value;
        }
    }

    return serialized;
};

export let getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
};

export const isMobile = (() => {
    return window.matchMedia("(max-width: 768px)").matches;
})();


export const STORAGE_USER_DELETED = 'gem_user_deleted';