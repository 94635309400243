import React from 'react';
import ReactDOM from 'react-dom';

import Auth from './components/Auth.jsx';

const AuthApp = {
    init: function () {
        const container = document.getElementById('auth-page');

        container && ReactDOM.render(<Auth />, container);
    }
};

export default AuthApp;