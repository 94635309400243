import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { inGamecenter, origin_by_login, Backend, getShortLocale } from "@vkplay/shared";

import messagesEn from "../i18n/en_US.json";
import messagesDe from "../i18n/de_DE.json";
import messagesFr from "../i18n/fr_FR.json";
import messagesEs from "../i18n/es_ES.json";
import messagesPl from "../i18n/pl_PL.json";
import messagesRu from "../i18n/ru_RU.json";
import messagesTr from "../i18n/tr_TR.json";
import messagesAr from "../i18n/ar_AA.json";
import messagesCn from "../i18n/zh_CN.json";
import messagesTw from "../i18n/zh_TW.json";
import messagesBr from "../i18n/pt_BR.json";
import messagesKo from "../i18n/ko_KR.json";
import messagesJa from "../i18n/ja_JP.json";

const messagesByLang = {
    "en": messagesEn,
    "de": messagesDe,
    "fr": messagesFr,
    "es": messagesEs,
    "pl": messagesPl,
    "ru": messagesRu,
    "tr": messagesTr,
    "ar": messagesAr,
    "cn": messagesCn,
    "tw": messagesTw,
    "br": messagesBr,
    "ko": messagesKo,
    "ja": messagesJa,
};

export default function withLang(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            const locale = window.__GEMS.lang;
            const lang = getShortLocale(locale);

            this.state = {
                user: window.__GEMS.current_user,
                socAuth: false,
                lang: lang || "en",
                locale: locale || "en_US",
                messages: messagesByLang[lang]
            };

            this.urls = {
                session: "/social/profile/v2/session"
            };
        }

        getUser = (userData) => {
            if (inGamecenter && !window.location.pathname.includes("/password")) {
                Backend.api_get(this.urls.session)
                    .then(data => {
                        const GEMS = window.__GEMS || {};
                        const session = data?.session;

                        if (!session) {
                            GEMS.no_session = true;
                        }

                        GEMS.current_user = session || {};
                        GEMS.token = data.token;
                        GEMS.country_id = data.country_id;
                        GEMS.region_id = data.region_id;
                        window.__GEMS = GEMS;

                        window.__GEMS.current_user = data.session;
                        window.__GEMS.token = data.token;
                        this.setState({
                            user: data.session,
                            locale: data.locale,
                            lang: getShortLocale(data.locale),
                        }, () => {
                            this.getMessages(data.locale);
                        });
                    });

                return false;
            }

            if (!this.state.user) {
                const user = userData || window.__GEMS.current_user || {},
                    origin = origin_by_login(user.login),
                    socAuth = origin !== "e" && origin !== "p";

                this.setState(() => ({
                    user,
                    socAuth,
                    locale: window.__GEMS.lang,
                    lang: getShortLocale(window.__GEMS.lang),
                }), () => {
                    this.getMessages(window.__GEMS.lang);
                });
            }
        }

        getMessages = (locale) => {
            const langShort = getShortLocale(locale);

            this.setState(() => ({ messages: messagesByLang[langShort] }));
        }

        componentDidMount() {
            window.addEventListener("gem_login", data => {
                this.getUser(data.detail);
            });

            if (inGamecenter) {
                this.getUser();
            }
        }

        render() {
            const { lang, messages } = this.state;

            return (
                <IntlProvider locale={lang} messages={messages}>
                    <WrappedComponent {...this.state} {...this.props} />
                </IntlProvider>
            );
        }
    };
}
