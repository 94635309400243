import React from "react";
import { useIntl } from "react-intl";

import Popup from "../Popup.jsx";
import Button from "../shared/Button.jsx";

import messages from "./SecurityMessages";

const TwoStepDisableWarning = ({ onClose }) => {
    const intl = useIntl();

    return (
        <Popup
            title={intl.formatMessage(messages.secondStepLogoutWarningTitle)}
            className={"secondstep_logout_warning_popup"}
            onClose={onClose}
            isOpen
        >
            <Button
                className={"secondstep_logout_warning_popup_button"}
                onClick={onClose}
            >
                {intl.formatMessage(messages.understood)}
            </Button>
        </Popup>
    );
};

export default TwoStepDisableWarning;