import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../SecurityMessages';

const LoginsImportedInfo = (props) => {
    const { logins } = props;
    const intl = useIntl();

    return (
        <div className="b-profile-box__logins">
            <div className="b-profile-box__security-field__header">
                {intl.formatMessage(messages.loginsImportedTitle)}
            </div>
            {logins.map((item) => {
                const isPhone = item.origin === 'p';
                const iconName = isPhone ? 'icon_phone' : 'icon_email';
                const iconClassName = isPhone ? 'icon-phone' : 'icon-email';
                const name = intl.formatMessage(isPhone ? messages.phoneLogin : messages.emailLogin);

                return (
                    <div
                        className="b-profile-box__row"
                        key={item.login}
                    >
                        <img
                            src={require(`../../../../img/icons/${iconName}.png`)}
                            className={iconClassName}
                            alt=""
                        />
                        <div className="b-profile-box__row__data">
                            <div className="b-profile-box__row__name">
                                {name}
                            </div>
                            <div className="b-profile-box__row__value b-profile-box__row__value_email">
                                {item.login}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default LoginsImportedInfo;