import CustomEvent from 'custom-event';
import * as Utils from './_utils.js';

const Popup = {
    selectors: {
        popup: '.js-popup',
        popupLinks: '.js-popup-link',
        popupClose: '.js-popup-close'
    },

    setMethods() {
        let $popupLinks = document.querySelectorAll(this.selectors.popupLinks),
            $popupClose = document.querySelectorAll(this.selectors.popupClose);


        for (const $link of $popupLinks) {
            $link.addEventListener('click', (e) => {
                e.preventDefault();

                const target = $link.dataset.target,
                    $popup = document.getElementById(target);

                if (!$popup) {
                    return;
                }

                if (typeof $popup.remove === 'function') {
                    $popup.remove();
                } else {
                    $popup.parentNode.removeChild($popup);
                }

                document.body.appendChild($popup);
                $popup.style.display = 'block';
                document.body.classList.add('is-popup-shown');

                const event = new CustomEvent('profilePopupOpened', { detail: { popup: $popup }});
                document.dispatchEvent(event);
            });
        }

        for (const $link of $popupClose) {
            $link.addEventListener('click', (e) => {
                e.preventDefault();

                let $popup = Utils.findAncestor($link, this.selectors.popup);

                if ($popup) {
                    $popup.style.display = 'none'
                }

                document.body.classList.remove('is-popup-shown');

                const event = new CustomEvent('profilePopupClosed', { detail: { popup: $popup }});
                document.dispatchEvent(event);
            });
        }

        if (location.search.indexOf('change_avatar=1') > -1) {
            let $changeAvatarLink = document.querySelector('[data-target=gallery]');

            $changeAvatarLink.click();
        }


    },

    init() {
        this.setMethods();
    }
};

export default Popup;
