import React from "react";
import { IntlProvider } from "react-intl";
import withLang from "../withLang.jsx";
import RegionContent from "./RegionContent.jsx";

const Region = ({ messages, lang }) => {
    return (
        <IntlProvider locale={lang} messages={messages}>
            <RegionContent />
        </IntlProvider>
    );
};

export default withLang(Region);
