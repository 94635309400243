import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'delete.header',
        defaultMessage: 'Личные данные',
    },
    descr: {
        id: 'delete.descr',
        defaultMessage: 'Получите подробную информацию о вашем аккаунте, а также о его свойствах и возможностях.',
    },
    deleteAccount: {
        id: 'delete.link',
        defaultMessage: 'Удалить аккаунт',
    },
    deletePopupTitle: {
        id: 'delete.popup_title',
        defaultMessage: 'Удаление аккаунта',
    },
    deletePopupDescr: {
        id: 'delete.popup_descr',
        defaultMessage: 'Вы уверены, что хотите навсегда удалить аккаунт? Отменить это действие будет невозможно',
    },
    deletePopupAlert: {
        id: 'delete.popup_alert',
        defaultMessage: 'Удаление учетной записи может занимать от нескольких секунд до нескольких дней, в зависимости от количества регистраций в различных играх. После подтверждения удаления создание нового аккаунта с такими же параметрами временно будет недоступно.',
    },
    deletePopupCancel: {
        id: 'delete.cancel',
        defaultMessage: 'Я передумал',
    },
    inProgress: {
        id: 'delete.in_progress',
        defaultMessage: 'Выполняется удаление аккаунта',
    }
});