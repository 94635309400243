import React from "react";
import ReactDOM from "react-dom";

import ManageAvatar from "./components/AvatarSettings.jsx";

class AvatarSettings {
    constructor() {
        this.showPopup = false;
        this.activeTab = "upload";
    }

    togglePopup = (e) => {
        this.showPopup = true;
        this.activeTab = e.target.dataset.target;
        this.render();
    }

    render = () => {
        let avatarSettingsEl = document.getElementById("avatar-settings");

        avatarSettingsEl && ReactDOM.render(<ManageAvatar
            isOpen={this.showPopup}
            activeTab={this.activeTab}
        />, avatarSettingsEl);
    }

    init = () => {
        let changeAvatarBtn = document.querySelectorAll(".js-change-avatar");

        for (let btn of changeAvatarBtn) {
            btn.addEventListener("click", this.togglePopup);
        }
        this.render();
    }
}

export default new AvatarSettings();
