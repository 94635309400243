import React, { Component } from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import withLang from "./withLang.jsx";
import NewsletterContent from "./NewsletterContent.jsx";

class Newsletter extends Component {

    render() {
        let { lang, messages, user } = this.props,
            newsletterProps = {
                user: user
            };


        return (
            <IntlProvider locale={lang} messages={messages}>
                <NewsletterContent {...newsletterProps} />
            </IntlProvider>
        );
    }
}

export default withLang(Newsletter);
