import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";

const DropdownSelector = ({ list = [], instance, selected, handleSelect, defaultLabel, defaultSelect }) => {
    const [opened, setOpened] = useState(false);
    const dropdown = useRef();

    const toggleDropdown = () => {
        setOpened(!opened);
    };

    const onSelect = (value) => {
        handleSelect({
            value: value,
            ...(instance && { instance: instance })
        });
    };

    const handleClickOutside = (e) => {
        if (opened && !dropdown.current.contains(e.target)) {
            setOpened(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);

        return (() => {
            window.removeEventListener("click", handleClickOutside);
        });
    }, [opened]);

    return (
        <div className={cn("ph-form__field ph-select", {
            "ph-select_opened": opened,
            "ph-select_has-selected": defaultSelect || selected
        })}
			 ref={dropdown}
			 onClick={toggleDropdown}>
            <div className="ph-select__default">
                {defaultLabel &&
					<span className="ph-select__default-label">
				    	{defaultLabel}
					</span>
                }
                {selected || defaultSelect &&
					<span className="ph-select__default-select">
					    {selected || defaultSelect}
					</span>
                }
                <span className="ph-select__dropdown-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="46" viewBox="0 0 8 4">
                        <path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" d="M7 .683L3.98 4 1 .683 1.4.25l2.58 2.871L6.6.25z"></path>
                    </svg>
                </span>
            </div>
            <div className="ph-select__dropdown">
                <ul className="ph-select__dropdown-list js-dropdown-list" style={{ maxHeight: "250px" }}>
                    {list.map(item => {
                        return (
                            <li key={`${instance}-${item.value}`} className="ph-select__dropdown-code">
                                <a className="b-form__select-dropdown-link js-select-link"
								   onClick={() => { onSelect(item.value); }}>
                                    {item.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};


export default DropdownSelector;
